import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

var horizonalLinePlugin = {
    id: 'horizontalLine',

    afterDraw: (chartInstance) => {
        if(chartInstance.options.verticalLines && chartInstance.options.verticalLines.length > 0) {
            var canvas = chartInstance.chart;
            var ctx = canvas.ctx;
            var xScale = chartInstance.scales["x-axis-0"];

            for(let line of chartInstance.options.verticalLines){
                let canDraw = chartInstance.config.data.labels.find((e) => e == line.label);

                if(!canDraw) continue;

                var x = xScale.getPixelForValue(line.label);

                ctx.lineWidth = line.width;

                ctx.beginPath();
                ctx.moveTo(x, line.my);
                ctx.lineTo(x, canvas.height - line.my);
                ctx.strokeStyle = line.color;
                ctx.stroke();
            }
        }
    }
};

export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: ['options'],

    mounted () {
        this.addPlugin(horizonalLinePlugin);
        this.renderChart(this.chartData, this.options);
    },

    watch: {
        options: {
            deep: true,

            handler: function() {
                this.$data._chart.options = this.options;
                this.$data._chart.update();
            }
        }
    }
};