<template>
    <Fragment :progress="progress" :error="error" @reload="load()">
        <v-simple-table v-if="patient && patient.reports && patient.reports.length > 0">
            <thead>
                <tr>
                    <th class="primary--text">
                        {{ $t('patient.report.list.id') }}
                    </th>
                    <th class="primary--text">
                        {{ $t('patient.report.list.preview') }}
                    </th>
                    <th class="primary--text">
                        {{ $t('patient.report.list.language') }}
                    </th>
                    <th class="primary--text">
                        {{ $t('patient.report.list.created_at') }}
                    </th>
                    <th class="primary--text">
                        {{ $t('patient.report.list.user') }}
                    </th>
                    <th class="primary--text w-action">
                        {{ $t('patient.report.list.delete') }}
                    </th>
                </tr>
            </thead>

            <tbody style="white-space: nowrap;">
                <tr v-for="(r, index) in patient.reports" :key="index">
                    <td>
                        <v-icon size="16">mdi-music-accidental-sharp</v-icon>{{r._id}}
                    </td>

                    <td>
                        <a :href="$store.state.config.api.reports + r.file" target="_blank">{{ $t('patient.report.preview.open_report') }}</a>
                    </td>

                    <td>
                        {{$t("langs." + r.locale)}}
                    </td>

                    <td>
                        <span>{{new Date(r.time).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric",}) + " " + new Date(r.time).toLocaleTimeString()}}</span>
                    </td>

                    <td>
                        <v-icon size="20">mdi-account</v-icon> {{r.user.name}}
                    </td>

                    <td class="w-action">
                        <v-btn
                            fab
                            color="red darken-1"
                            x-small
                            dark
                            depressed
                            @click="form.report_id = r._id; confirmationDialog = true"
                        >
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <Card v-else>
            <v-container fluid class="pa-2 d-flex align-center justify-center">
                <div class="text-center">
                    <v-icon size="60" class="mb-2">mdi-timer-sand</v-icon>

                    <div class="text-h6 font-weight-regular">{{ $t('patient.report.no_data') }}</div>
                </div>
            </v-container>
        </Card>

        <div class="d-flex justify-end mt-3">
            <v-btn @click="reportGeneratorDialog = true" class="primary">
                <v-icon left>mdi-plus</v-icon> {{ $t("patient.report.create.title") }}
            </v-btn>
        </div>
        
        <Dialog
            v-if="confirmationDialog"
            :open="confirmationDialog"
            :no="$t('no')"
            :yes="$t('yes')"
            @no="confirmationDialog = false; load();"
            @yes="deleteReport();"
            :title="$t('patient.report.delete_confirmation.title')"
            :saveProgress="deleteProgress"
        >
            {{ $t('patient.report.delete_confirmation.content') }}
        </Dialog>
        
        <PatientReportGenerator
            v-if="reportGeneratorDialog"
            :open="reportGeneratorDialog"
            :patient="patient._id"
            @cancel="reportGeneratorDialog = false; load();"
        />

    </Fragment>
</template>

<script>
import Fragment from "../components/Fragment.vue";
import Dialog from "../components/Dialog.vue";
import Card from "../components/Card.vue";
import PatientReportGenerator from "../dialogs/PatientReportGenerator.vue";

export default {
    name: "PatientReport",
    components: {
        Fragment,
        Dialog,
        Card,
        PatientReportGenerator
    },

    async mounted() {
        this.form.id = this.$route.params.id;
        this.load();
    },

    data: () => ({
        form: {
            id: null,
            report_id: null
        },

        error: {
            type: null,
            msg: null,
            code: null
        },

        patient: null,
        progress: true,
        reportGeneratorDialog: false,
        confirmationDialog: false,
        deleteProgress: false
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load() {
            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }
            
            const res = await this.$store.dispatch("api/patient/get", { id: this.form.id });

            if (res.status == 200) {
                this.patient = res.data.patient;
                this.progress = false;
            } else {
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }
        },

        async deleteReport() {

            this.deleteProgress = true;

            const res = await this.$store.dispatch("api/patient/delete_report", this.form);
            if (res.status != 200) {
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }
            
            await this.load();
            this.confirmationDialog = false; 
            this.deleteProgress = false;
        }
    }
};
</script>
