<template>
    <Dialog :fullscreen="true" :progress="progress" :title="title" icon="mdi-run" :open="open" :cancel="$t('close')" :saveProgress="saveProgress" @cancel="$emit('cancel')">
        <Card>
            <v-row>
                <v-col class="d-flex col-12 col-md-4">
                    <v-btn 
                        v-if="!progress" 
                        :loading="generateCsvProgress" 
                        :disabled="saveProgress" 
                        color="primary"
                        rounded
                        @click="generateCsv()"
                    > 
                        <v-icon left>mdi-download</v-icon> {{$t('patient.training.test.csv')}}  
                    </v-btn>

                    <v-btn
                        v-if="!progress"
                        rounded
                        color="secondary"
                        class="ml-2"
                    >
                        {{this.test.entries.length}}
                    </v-btn>

                    <v-btn
                        rounded
                        color="secondary"
                        class="ml-2"
                    >
                        {{this.from}} | {{this.to}}
                    </v-btn>
                </v-col>

                <v-col class="d-flex col-12 col-md-4">
                    <v-slider
                        v-model="slider"
                        :disabled="saveProgress"
                        thumb-label
                        hide-details
                        :min="1"
                        :max="max_slider"
                        @change="chartsLoad()"
                    />
                </v-col>

                <v-col class="d-flex col-12 col-md-4 justify-center justify-md-end">
                    <v-btn
                        :disabled="position == 0 || saveProgress"
                        rounded
                        color="primary"
                        @click="position--; chartsLoad();"
                    >
                        <v-icon>mdi-skip-previous-outline</v-icon>
                    </v-btn>

                    <v-btn
                        rounded
                        color="secondary"
                        class="mr-2 ml-2"
                    >
                        {{position}} / {{max_position}}
                    </v-btn>

                    <v-btn
                        :disabled="position == max_position || saveProgress"
                        rounded
                        color="primary"
                        @click="position++; chartsLoad();"
                    >
                        <v-icon>mdi-skip-next-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </Card>

        <Card :title="$t('patient.training.test.efficiency')">
            <LineChart
                ref="ch_eff"
                :data="chart_eff"
                :min="0"
                :max="200"
                height="350px"
            />
        </Card>
    </Dialog>
</template>

<script>
import Dialog from "../components/Dialog.vue";
import Card from "../components/Card.vue";
import LineChart from "../components/LineChart.vue";

export default {
    components: {
        Dialog,
        Card,
        LineChart
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        id:{
            type: String,
            default: null
        },

        title:{
            type: String,
            default: null
        }
    },

    mounted(){
        this.load();
    },

    data: () => ({
        progress: true,
        saveProgress: false,
        generateCsvProgress: false,
        test: null,

        slider: 100,
        slider_step: 5,
        max_slider: 100,
        position: 0,
        max_position: 0,
        from: 0,
        to: 0,
        points: [],

        chart_eff: {
            labels: [],
            datasets: [
                LineChart.methods.newDataset("", "#209AF1"),
            ]
        }
    }),

    methods: {
        async load(){
            const res = await this.$store.dispatch("api/patient/test_get", { id: this.id });

            if(res.status == 200){
                this.test = res.data;

                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.chartsLoad();
                    });
                });

                this.progress = false;
            }
        },

        chartsLoad(){
            const chart_eff = this.$refs.ch_eff;

            this.chart_eff.datasets[0].label = this.$t('patient.training.test.efficiency');

            const eff = {
                labels: [],
                datasets: this.chart_eff.datasets
            }

            eff.datasets[0].data = [];

            if(this.position < 0) this.position = 0;
            if(this.slider == 0) this.slider = 1;

            if(this.slider_step * this.max_slider > this.test.entries.length) this.max_slider = Math.ceil(this.test.entries.length / this.slider_step);

            let limit = this.slider_step * this.slider;

            this.max_position = Math.floor(this.test.entries.length / limit);
            if(limit == this.test.entries.length) this.max_position--;

            if(limit > this.test.entries.length) limit = this.test.entries.length;

            this.from = limit * this.position;
            this.to = limit * (this.position + 1);

            if(this.position >= this.max_position) {
                this.to = this.test.entries.length;
                this.from = this.to - limit;
                this.position = this.max_position;
            }

            for(let i = this.from; i < this.to; i++){
                let entry = this.test.entries[i];
                
                eff.labels.push(entry.n);
                eff.datasets[0].data.push(entry.eff);
            }

            this.chart_eff = eff;
        },

        save(){

        },

        its(index) {
            return Math.floor(((this.test.entries[index].time - this.test.entries[0].time) / 1000) * 10) / 10;
        },

        async generateCsv(){
            this.generateCsvProgress = true;
            const res = await this.$store.dispatch("api/patient/generate_test_csv", { id: this.id });

            if(res.status == 200){
                const temp = document.createElement("a");
                temp.href = this.$store.state.config.api.tests + this.id + ".csv";
                temp.download = this.id;
                temp.click();

                this.generateCsvProgress = false;
            }
        }
    }
};
</script>
