<template>
    <Dialog :progress="progress" :title="$t('patient.report.report_notes.title')" maxWidth="700px" :open="open" :saveProgress="saveProgress" @cancel="$emit('cancel')" @save="generate()" :save="$t('patient.report.report_notes.generate')">
        <v-select
            class="mb-3"
            v-model="form.locale"
            @change="generateNotes()"
            :items="langs"
            item-text="name"
            item-value="value"
            :label="$t('patient.report.report_notes.choose_lang')"
            :disabled="saveProgress"
            hide-details="true"
            persistent-hint
            outlined
            prepend-inner-icon="mdi-translate"
        />
        
        <v-textarea
            v-model="form.notes"
            :label="$t('patient.report.report_notes.description')" 
            rows=6
            hide-details
            :disabled="saveProgress"
            outlined
        />
    </Dialog>
</template>

<style lang="scss" scoped>
    ::v-deep {
        ::-webkit-scrollbar-track {
            background-color: transparent !important;
            border-left: 0px;
        }

        ::-webkit-scrollbar {
            width: 5px !important;
        }
    }
</style>

<script>
import Dialog from "../components/Dialog.vue";

export default {
    components: {
        Dialog
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        patient: {
            type: String,
            default: null
        },
    },

    mounted(){
        for(let locale of Object.keys(this.$i18n.messages)){
            this.langs.push({
                name: this.$t("langs."+locale),
                value: locale
            });
        }

        this.form.id = this.patient;
        this.load();
    },

    data: (vm) => ({
        form: {
            id: null,
            locale: vm.$i18n.locale,
            notes: null
        },

        progress: true,
        saveProgress: false,
        langs: [],
        data: null
    }),

    methods: {
        async load(){
            this.progress = true;

            const res = await this.$store.dispatch("api/patient/generate_report_data", { id: this.form.id });

            if (res.status == 200) {
                this.data = res.data;
                this.generateNotes();
                this.progress = false;
            }
        },

        generateNotes(){
            this.form.notes = "";
            let counter = 0;

            for (let c of this.data.calls) {
                if (counter > 0) this.form.notes = this.form.notes + "\r\n";
                this.form.notes = this.form.notes + "• " + new Date(c.end).toLocaleString((this.form.locale == "en" ? "en-GB" : this.form.locale), { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}) 
                    + " (" + (c.user ? (c.user.title ? c.user.title + " " + c.user.name : c.user.name) : '-') + ")" 
                    + (c.notes ? ":" + "\r\n" + c.notes : "");
                counter++;
            }
        },

        async generate(){
            this.saveProgress = true;

            const res = await this.$store.dispatch("api/patient/generate_report", this.form);

            if (res.status == 200) {
                this.saveProgress = false;
                this.$emit('cancel');
            }
        },
    }
};
</script>
