<template>
    <line-chart 
        :styles="{height: height, position: 'relative'}" 
        :chart-data="data" 
        :options="options" 
    />
</template>

<script>
import LineChart from '../../plugins/linechart';

export default {
    components: {LineChart},
    
    props: {
        height:{
            type: String,
            default: '250px'
        },

        data:{
            type: Object
        },

        min:{
            type: Number,
            default: 0
        },

        max:{
            type: Number,
            default: 50
        }
    },

    mounted(){
        this.options.onClick = this.handle;
    },

    data: (vm) => ({
        markers: [],

        options: {
            responsive: true,
            maintainAspectRatio: false,
            onClick: null,

            scales: {
                yAxes: [{
                    ticks: {
                        maxTicksLimit: 6,
                        beginAtZero: true,
                        min: vm.min,
                        max: vm.max,
                    }    
                }]
            },

            elements: {
                point: {
                    radius: vm.pointRadius,
                    pointStyle: vm.pointStyles,
                    hoverRadius: 6,
                }
            },

            tooltips: {
                enabled: true,
                mode: 'index',
                axis: 'x',
                intersect: false
            },

            verticalLines: []
        },
    }),

    methods:{
        newDataset(label, color, bgcolor){
            return {
                type: 'line',
                label: label,
                fill: (bgcolor) ? true : false,
                backgroundColor: bgcolor,
                borderColor: color,
                data: [],
                order: 2
            };
        },

        pointStyles(ctx){
            let index = ctx.dataIndex;
            let label = ctx.chart.config.data.labels[index];

            let marker = this.markers.find((e) => e.label == label);

            return (marker) ? marker.type : 'circle';
        },

        pointRadius(ctx){
            let index = ctx.dataIndex;
            let label = ctx.chart.config.data.labels[index];

            let marker = this.markers.find((e) => e.label == label);

            return (marker) ? marker.radius : 1;
        },

        addMarker(label, type, size, color){
            if (type == "verticalLine") {
                this.options.verticalLines.push({
                    label,
                    my: 32,
                    width: size,
                    color: (color ? color : "#FF0000")
                });
            } else {
                this.markers.push({
                    label,
                    type,
                    size
                });
            }
        },

        clearMarkers(){
            this.options.verticalLines = [];            
            this.markers = [];            
        },

        handle(evt, items) {
            if (items.length > 0) {
                const item = items[0];
                this.$emit('on-receive', {
                    index: item._index,
                    e: evt
                });
            }
        }
    }
}
</script>