<template>
    <Fragment :progress="progress" :error="error" @reload="load()">
        <ActionBar :updateTime="updateTime">
            <v-btn v-if="checked" :loading="saveProgress" :disabled="saveProgress" color="primary" @click="save()"> 
                <v-icon left>mdi-content-save-outline</v-icon> {{$t('save')}} 
            </v-btn>
            <v-btn v-if="!checked" :loading="saveProgress" :disabled="saveProgress" color="red darken-1" class="white--text" @click="save()"> 
                <v-icon left>mdi-check-bold</v-icon> {{$t('confirm')}} 
            </v-btn>
        </ActionBar>

        <v-simple-table class="mt-3">
            <thead>
                <tr>
                    <th class="primary--text">
                        <SortHeader 
                            :text="$t('patient.videos.list.name')"
                            v-model="formVideos.sort"
                            @change="load()"
                            field="name"
                            type="alphanum"
                        />
                    </th>
                    <th class="primary--text">
                        <SortHeader 
                            :text="$t('patient.videos.list.created_at')"
                            v-model="formVideos.sort"
                            @change="load()"
                            field="createdAt"
                            type="time"
                        />
                    </th>
                    <th class="primary--text text-center">
                        {{ $t('patient.videos.list.views_status') }}
                    </th>
                    <th class="primary--text text-center">
                        {{ $t('patient.videos.list.blocked') }}
                    </th>
                    <th class="primary--text w-action">
                        {{ $t("patient.videos.list.edit") }}
                    </th>
                </tr>
            </thead>
            <tbody style="white-space: nowrap;">
                <tr v-for="(u, index) in videos" :key="index">
                    <td class="pt-3 pb-3">
                        <tr>
                            <span class="font-weight-bold">{{ u.name }}</span>
                        </tr>
                        <tr>
                            <span class="grey--text text--darken-2 d-block text-truncate" style="max-width: 400px;">
                                <v-icon size="16">mdi-clipboard-text-outline</v-icon> {{ (u.info) ? u.info : $t("video.list.no_info") }}
                            </span>
                        </tr>
                    </td>
                    
                    <td>
                        <v-icon class="mr-1" size="16">mdi-calendar-range</v-icon> {{ (new Date(u.createdAt)).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric",}) +
                         " " + (new Date(u.createdAt)).toLocaleTimeString([], {hour: "2-digit", minute: "2-digit",}) }}
                    </td>

                    <td class="text-center">
                        <span v-if="u.views" class="green--text"> <v-icon class="mr-1 green--text" size="16">mdi-check</v-icon> {{ $t("patient.videos.list.watched") + " (" + u.views + ")" }} </span>
                        <span v-else class="red--text text--darken-1"> <v-icon class="mr-1 red--text text--darken-1" size="16">mdi-close</v-icon> {{ $t("patient.videos.list.unwatched") }} </span>
                    </td>

                    <td class="w-action">
                        <div class="d-flex justify-center">
                            <v-checkbox v-if="u.enabled"
                                class="ma-0 ml-2 pa-0"
                                v-model="u.patientBlocked"
                                on-icon="mdi-close-box"
                                color="red darken-1"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox v-else
                                class="ma-0 ml-2 pa-0"
                                input-value="true"
                                on-icon="mdi-close-box"
                                disabled
                                hide-details
                            ></v-checkbox>
                        </div>
                    </td>

                    <td class="w-action">
                        <v-btn
                            fab
                            color="primary"
                            x-small
                            dark
                            depressed
                            :to="{ name: 'video', params: { id: u._id, routefrom: 'patients+patient.'+form.id } }"
                            ><v-icon>mdi-cog</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        
        <v-pagination
            class="mt-3"
            @input="load(form.filter)"
            v-if="pagination.total > 1"
            v-model="form.page"
            :length="pagination.total"
            :total-visible="7"
        />

        <SaveSnackBar v-model="save_snackbar" :text="$t('save_snackbar')" />
    </Fragment>
</template>

<script>
import Fragment from "../components/Fragment.vue";
import SortHeader from "../components/SortHeader.vue";
import ActionBar from "../components/ActionBar.vue";
import SaveSnackBar from "../components/SaveSnackBar.vue";

export default {
    name: "PatientVideos",
    components: {
        Fragment,
        ActionBar,
        SortHeader,
        SaveSnackBar
    },

    async mounted() {
        this.form.id = this.$route.params.id;
        this.load();
    },

    data: () => ({
        error: {
            type: null,
            msg: null,
            code: null
        },
        
        pagination: {
            total: 0,
            filter: null
        },
        
        formVideos: {
            sort: "createdAt name ",
            page: null,
            limit: null,
            filter: null
        },

        form: {
            id: null,
            name: null,
            sex: null,
            birth_date: null,
            national_id: null,
            videos: null,
            enabled: null
        },
        
        videos: null,

        updateTime: null,
        checked: null,

        progress: true,
        saveProgress: false,
        save_snackbar: false,
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load() {

            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }
            
            this.selectedEntity();
            
            const res = await this.$store.dispatch("api/patient/get", { id: this.form.id });
            const resVideos = await this.$store.dispatch("api/video/all", this.formVideos);

            if (res.status == 200 && resVideos.status == 200) {
                let patient = res.data.patient;

                this.form.videos = patient.videos;
                this.form.name = patient.name;
                this.form.sex = patient.sex;
                this.form.birth_date = patient.birth_date;
                this.form.national_id = patient.national_id;
                this.form.enabled = patient.enabled;
                this.updateTime = patient.updateTime ? new Date(patient.updateTime) : null;
                this.checked = patient.checked;

                this.videos = resVideos.data;
                this.pagination.total = resVideos.pages;

                for (let v of this.videos) {
                    for (let vp of this.form.videos) {
                        if (vp.video.toString() == v._id.toString()) {
                            v.views = vp.views;
                            v.patientBlocked = !vp.enabled;
                            break;
                        }
                    }
                }

                this.progress = false;
            } else {
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }

            return;
        },

        async save() {
            this.saveProgress = true;

            for (let v of this.videos) {
                let found = false;
                for (let vp of this.form.videos) {
                    if (vp.video.toString() == v._id.toString()) {
                        found = true;
                        vp.enabled = !v.patientBlocked;
                        break;
                    }
                }
                if (!found && v.patientBlocked) {
                    this.form.videos.push({
                        video: v._id.toString(),
                        views: 0,
                        enabled: false
                    });
                }
            }

            await this.$store.dispatch("api/patient/update", this.form);
            await this.load();
            this.saveProgress = false;
            this.save_snackbar = true;
        },

        selectedEntity(){
            let entity = this.$store.state.api.user.selectedEntity;
            if(!entity) return null;
            this.formVideos.entity = entity._id;
            return entity._id;
        }
    }
};
</script>
