import Chart from 'chart.js'
import { Line, mixins, generateChart } from 'vue-chartjs';
const { reactiveProp } = mixins;

//Chart.defaults.LineWithLine = Chart.defaults.line;

var horizonalLinePlugin = {
    id: 'horizontalLine',

    afterDraw: (chartInstance) => {
        if(chartInstance.options.verticalLines) {
            var canvas = chartInstance.chart;
            var ctx = canvas.ctx;
            var xScale = chartInstance.scales["x-axis-0"];

            for(let line of chartInstance.options.verticalLines){
                let canDraw = chartInstance.config.data.labels.find((e) => e == line.label);

                if(!canDraw) continue;

                var x = xScale.getPixelForValue(line.label);

                ctx.lineWidth = line.width;

                ctx.beginPath();
                ctx.moveTo(x, line.my);
                ctx.lineTo(x, canvas.height - line.my);
                ctx.strokeStyle = line.color;
                ctx.stroke();
            }
        }
    }
};

export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['options'],

    mounted () {
        this.addPlugin(horizonalLinePlugin);
        this.renderChart(this.chartData, this.options);
    },

    watch: {
        options: {
            deep: true,

            handler: function() {
                this.$data._chart.options = this.options;
                this.$data._chart.update();
            }
        }
    }
};

// Chart.controllers.LineWithLine = Chart.controllers.line.extend({
//     name: "LineWithLine",

//     draw: function (chartInstance) {
//         var yValue;
//         var yScale = chartInstance.scales["y-axis-0"];
//         var canvas = chartInstance.chart;
//         var ctx = canvas.ctx;
//         var index;
//         var line;
//         var style;

//         if (chartInstance.options.horizontalLine) {
//             for (index = 0; index < chartInstance.options.horizontalLine.length; index++) {
//                 line = chartInstance.options.horizontalLine[index];

//                 if (!line.style) {
//                     style = "#080808";
//                 } else {
//                     style = line.style;
//                 }

//                 if (line.y) {
//                     yValue = yScale.getPixelForValue(line.y);
//                 } else {
//                     yValue = 0;
//                 }
//                 ctx.lineWidth = 3;

//                 if (yValue) {
//                     window.chart = chartInstance;
//                     ctx.beginPath();
//                     ctx.moveTo(0, yValue);
//                     ctx.lineTo(canvas.width, yValue);
//                     ctx.strokeStyle = style;
//                     ctx.stroke();
//                 }

//                 if (line.text) {
//                     ctx.fillStyle = style;
//                     ctx.fillText(line.text, 0, yValue + ctx.lineWidth);
//                 }
//             }
//             return;
//         }
//     }
// });

// const CustomLine = generateChart('custom-line', 'LineWithLine')