<template>
    <Page icon="mdi-medical-bag" :title="form.name" :progress="progress" :error="error" @reload="load()">
        <v-tabs class="elevation-1">
            <v-tab @click="tab = 0">{{$t('patient.tabs.main')}}</v-tab>
            <v-tab @click="tab = 1" v-if="user.hasPerm('device') && devices && devices.length > 0 && !anonymised">{{$t('patient.tabs.devices')}}</v-tab>
            <v-tab @click="tab = 2" v-if="user.hasPerm('call') && calls && calls.length > 0 && !anonymised">{{$t('patient.tabs.calls')}}</v-tab>
            <v-tab @click="tab = 3" v-if="user.hasPerm('video') && !anonymised">{{$t('patient.tabs.videos')}}</v-tab>
            <v-tab @click="tab = 4">{{$t('patient.tabs.balance')}}</v-tab>
            <v-tab @click="tab = 5">{{$t('patient.tabs.training')}}</v-tab>
            <v-tab @click="tab = 6" v-if="!anonymised">{{$t('patient.tabs.report')}}</v-tab>
        </v-tabs>

        <template v-if="tab == 0">
            <PatientGeneral class="mt-3" @reload="load()"/>
        </template>

        <template v-if="!anonymised && user.hasPerm('device') && tab == 1">
            <PatientDevices class="mt-3" />
        </template>

        <template v-if="!anonymised && user.hasPerm('call') && tab == 2">
            <PatientCalls class="mt-3" />
        </template>

        <template v-if="!anonymised && user.hasPerm('video') && tab == 3">
            <PatientVideos class="mt-3" />
        </template>

        <template v-if="tab == 4">
            <PatientBalance class="mt-3" />
        </template>

        <template v-if="tab == 5">
            <PatientTraining class="mt-3" />
        </template>

        <template v-if="!anonymised && tab == 6">
            <PatientReport class="mt-3" />
        </template>
    </Page>
</template>

<script>
import Page from "./components/Page.vue";
import Card from "./components/Card.vue";
import PatientGeneral from "./fragments/PatientGeneral.vue";
import PatientDevices from "./fragments/PatientDevices.vue";
import PatientVideos from "./fragments/PatientVideos.vue";
import PatientBalance from "./fragments/PatientBalance.vue";
import PatientTraining from "./fragments/PatientTraining.vue";
import PatientCalls from "./fragments/PatientCalls.vue";
import PatientReport from "./fragments/PatientReport.vue";

export default {
    name: "Patient",
    components: {
        Page,
        Card,
        PatientGeneral,
        PatientDevices,
        PatientVideos,
        PatientCalls,
        PatientBalance,
        PatientTraining,
        PatientReport
    },

    async mounted() {
        this.form.id = this.$route.params.id;
        this.load();
    },

    data: () => ({
        pickedDate: {
            year: null,
            month: null,
            day: null
        },
        
        error: {
            type: null,
            msg: null,
            code: null
        },

        form: {
            id: null,
            name: null,
            nationality: null,
            national_id: null,
            email: null,
            phone: null,
            home_address: null,
            post_address: null,
            growth: null,
            weight: null,
            sex: null,
            birth_date: null,
            notes: null,
            summary: null,
            enabled: null
        },

        devices: null,
        calls: null,
        updateTime: null,
        checked: null,
        anonymised: null,
        tab: 0,
        progress: true
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load() {
            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }

            const res = await this.$store.dispatch("api/patient/get", { id: this.form.id });

            if (res.status == 200) {
                let patient = res.data.patient;
                let devices = res.data.devices;
                let calls = res.data.calls;
                let date = new Date(patient.birth_date);

                this.pickedDate = {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate()
                }

                this.form.name = patient.name;
                this.form.nationality = patient.nationality;
                this.form.national_id = patient.national_id;
                this.form.email = patient.email;
                this.form.phone = patient.phone;
                this.form.home_address = patient.home_address;
                this.form.post_address = patient.post_address;
                this.form.growth = patient.growth;
                this.form.weight = patient.weight;
                this.form.sex = patient.sex;
                this.form.notes = patient.notes;
                this.form.summary = patient.summary;
                this.form.enabled = patient.enabled;
                this.form.birth_date = this.pickedDate.year + "-" + String(this.pickedDate.month).padStart(2, '0') + "-" +  String(this.pickedDate.day).padStart(2, '0');
                this.updateTime = patient.updateTime ? new Date(patient.updateTime) : null;
                this.checked = patient.checked;
                this.anonymised = patient.anonymised;
                this.devices = devices;
                this.calls = calls;

                this.progress = false;
            } else {
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }

            return;
        }
    }
};
</script>
