<template>
    <Fragment :progress="progress" :error="error" @reload="load()">
        <ActionBar :updateTime="updateTime">
            <v-btn v-if="checked" :loading="saveProgress" :disabled="saveProgress || dangerProgress || anonymised" color="primary" @click="save()"> 
                <v-icon left>mdi-content-save-outline</v-icon> {{$t('save')}} 
            </v-btn>
            <v-btn v-if="!checked" :loading="saveProgress" :disabled="saveProgress || dangerProgress || anonymised" color="red darken-1" class="white--text" @click="save()"> 
                <v-icon left>mdi-check-bold</v-icon> {{$t('confirm')}} 
            </v-btn>
        </ActionBar>

        <Card :title="$t('patient.main.patient_data')" class="mt-3">
            <v-row>
                <v-col class="col-12 col-md-8 pt-0">
                    <v-text-field
                        class="mt-3"
                        :label="$t('patient.form.name')"
                        v-model="form.name"
                        :error-messages="errors.name"
                        :hide-details="!errors.name"
                        :disabled="saveProgress || dangerProgress || anonymised"
                        @change="validate('name')"
                        outlined
                        prepend-inner-icon="mdi-account"
                    />
                </v-col>

                <v-col class="col-12 col-md-4 pt-0">
                    <v-select
                        class="mt-0 mt-md-3"
                        v-model="form.sex"
                        :label="$t('patient.form.sex')"
                        :items="sex_items"
                        :hide-details="!errors.sex"
                        :error-messages="errors.sex"
                        :disabled="saveProgress || dangerProgress || anonymised"
                        @change="validate('sex')"
                        item-text="name"
                        item-value="key"
                        outlined
                        prepend-inner-icon="mdi-gender-male-female"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col class="col-12 col-md-8 pt-0">    
                    <v-text-field
                        class="mt-0"
                        :label="$t('patient.form.national_id')"
                        v-model="form.national_id"
                        :error-messages="errors.national_id"
                        :hide-details="!errors.national_id"
                        :disabled="saveProgress || dangerProgress || anonymised"
                        @change="validate('national_id')"
                        outlined
                        prepend-inner-icon="mdi-card-account-details-outline"
                    />
                </v-col>

                <v-col class="col-12 col-md-4 pt-0">
                    <v-text-field
                        class="mt-0"
                        :label="$t('patient.form.nationality')"
                        v-model="form.nationality"
                        :error-messages="errors.nationality"
                        :hide-details="!errors.nationality"
                        :disabled="saveProgress || dangerProgress || anonymised"
                        @change="validate('nationality')"
                        outlined
                        prepend-inner-icon="mdi-earth"
                    />
                </v-col>
            </v-row>

            <div class="text-left mt-2 font-weight-light text-caption mb-2">{{$t('patient.form.birth_date')}}</div>
            <DateInput
                v-model="pickedDate"
                @input="validate('birth_date')"
                :errorMessage="errors.birth_date"
                :hideDetails="anonymised"
                :disabled="saveProgress || dangerProgress || anonymised"
                :yearLabel="$t('year')"
                :monthLabel="$t('month')"
                :dayLabel="$t('day')"
            />

            <v-row>
                <v-col class="col-12 col-md-6 pt-0">
                    <v-text-field
                        class="mt-6"
                        :label="$t('patient.form.growth')"
                        v-model="form.growth"
                        :error-messages="errors.growth"
                        :hide-details="!errors.growth"
                        :disabled="saveProgress || dangerProgress || anonymised"
                        @change="validate('growth')"
                        outlined
                        prepend-inner-icon="mdi-human-male-height"
                    />
                </v-col>

                <v-col class="col-12 col-md-6 pt-0">
                    <v-text-field
                        class="mt-0 mt-md-6"
                        :label="$t('patient.form.weight')"
                        v-model="form.weight"
                        :error-messages="errors.weight"
                        :hide-details="!errors.weight"
                        :disabled="saveProgress || dangerProgress || anonymised"
                        @change="validate('weight')"
                        outlined
                        prepend-inner-icon="mdi-scale"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col class="col-12 col-md-6 pt-0">
                    <v-text-field
                        class="mt-0"
                        :label="$t('patient.form.phone')"
                        v-model="form.phone"
                        :error-messages="errors.phone"
                        :hide-details="!errors.phone"
                        :disabled="saveProgress || dangerProgress || anonymised"
                        @change="validate('phone')"
                        outlined
                        prepend-inner-icon="mdi-phone"
                    />
                </v-col>

                <v-col class="col-12 col-md-6 pt-0">
                    <v-text-field
                        class="mt-0"
                        :label="$t('patient.form.email')"
                        v-model="form.email"
                        :error-messages="errors.email"
                        :hide-details="!errors.email"
                        :disabled="saveProgress || dangerProgress || anonymised"
                        @change="validate('email')"
                        outlined
                        prepend-inner-icon="mdi-email"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col class="col-12 col-md-6 pt-0">
                    <v-text-field
                        class="mt-0"
                        :label="$t('patient.form.home_address')"
                        v-model="form.home_address"
                        :error-messages="errors.home_address"
                        :hide-details="!errors.home_address"
                        :disabled="saveProgress || dangerProgress || anonymised"
                        @change="validate('home_address')"
                        outlined
                        prepend-inner-icon="mdi-map-marker"
                    />
                </v-col>

                <v-col class="col-12 col-md-6 pt-0">
                    <v-text-field
                        class="mt-0"
                        :label="$t('patient.form.post_address')"
                        v-model="form.post_address"
                        :error-messages="errors.post_address"
                        :hide-details="!errors.post_address"
                        :disabled="saveProgress || dangerProgress || anonymised"
                        @change="validate('post_address')"
                        outlined
                        prepend-inner-icon="mdi-mailbox"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col class="col-12 pt-0">
                    <v-textarea
                        class="mt-0"
                        :label="$t('patient.form.notes')"
                        v-model="form.notes"
                        rows=3
                        auto-grow
                        :error-messages="errors.notes"
                        :hide-details="!errors.notes"
                        :disabled="saveProgress || dangerProgress || anonymised"
                        @change="validate('notes')"
                        outlined
                        prepend-inner-icon="mdi-note-outline"
                    />
                </v-col>
            </v-row>
        </Card>

        <DangerZone
            v-if="user.hasPerm('device')"
            @btnClick="beforeDangerZoneAction()"
            v-model="dialogDangerItem"
            :data="[
                {
                    itemKey: 'changeStatus',
                    itemName: $t('patient.danger_zone.change_status'),
                    itemInfo: $t('patient.danger_zone.change_status_info') + ' ',
                    itemInfoGreen: (form.enabled ? $t('active') : null),
                    itemInfoRed: (form.enabled ? null : $t('inactive')),
                    itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'changeStatus' ? dangerProgress : false),
                    itemDisabled: anonymised
                },

                {
                    itemKey: 'anonymise',
                    itemName: $t('patient.danger_zone.anonymise'),
                    itemInfo: $t('patient.danger_zone.anonymise_info') + ' ',
                    itemInfoGreen: (!anonymised ? $t('patient.danger_zone.public') : null),
                    itemInfoRed: (!anonymised ? null : $t('patient.danger_zone.anonymised')),
                    itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'anonymise' ? dangerProgress : false),
                    itemDisabled: anonymised
                },

                {
                    itemKey: 'delete',
                    itemName: $t('patient.danger_zone.delete'),
                    itemInfo: $t('patient.danger_zone.delete_info'),
                    itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'delete' ? dangerProgress : false)
                }
            ]"
            :disabled="saveProgress || dangerProgress"
        />

        <DangerZoneConfirm
            v-if="dialogDanger"
            :title="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return $t('patient.danger_zone.change_status');
                    break;
                case 'anonymise': 
                    return $t('patient.danger_zone.anonymise');
                    break;
                case 'delete':
                    return $t('patient.danger_zone.delete');
            }}"
            :description="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return $t('patient.danger_zone.dialog.change_status_desc');
                    break;
                case 'anonymise': 
                    return $t('patient.danger_zone.dialog.anonymise_desc');
                    break;
                case 'delete':
                    return $t('patient.danger_zone.dialog.delete_desc');
            }}"
            :descriptionContent="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return (form.enabled ? $t('inactive') : $t('active'));
                    break;
                case 'anonymise': 
                    return form.name;
                    break;
                case 'delete':
                    return form.name;
            }}"
            :checkText="() => form.name"
            :dependenciesData="dependenciesData" 
            :open="dialogDanger"
            :save="dialogDangerItem.itemName"
            :saveProgress="dangerDialogSaveProgress"
            @cancel="dialogDanger = false" 
            @save="dangerZoneAction(dialogDangerItem)"
        />

        <SaveSnackBar v-model="save_snackbar" :text="$t('save_snackbar')" />
    </Fragment>
</template>

<script>
import Card from "../components/Card.vue";
import Fragment from "../components/Fragment.vue";
import DateInput from "../components/DateInput.vue";
import ActionBar from "../components/ActionBar.vue";
import DangerZone from "../components/DangerZone.vue";
import DangerZoneConfirm from "../dialogs/DangerZoneConfirm.vue";
import validator from "../../plugins/validator";
import * as patientValidation from "../../api/patient/validation";
import SaveSnackBar from "../components/SaveSnackBar.vue";

export default {
    name: "PatientGeneral",
    components: {
        Card,
        ActionBar,
        DateInput,
        DangerZone,
        DangerZoneConfirm,
        Fragment,
        SaveSnackBar
    },

    async mounted() {
        this.form.id = this.$route.params.id;

        this.sex_items.push({
            key: "female",
            name: this.$t("sex_types.female.name")
        });

        this.sex_items.push({
            key: "male",
            name: this.$t("sex_types.male.name")
        });

        this.load();
    },

    data: () => ({
        pickedDate: {
            year: null,
            month: null,
            day: null
        },

        error: {
            type: null,
            msg: null,
            code: null
        },

        form: {
            id: null,
            name: null,
            nationality: null,
            national_id: null,
            email: null,
            phone: null,
            home_address: null,
            post_address: null,
            growth: null,
            weight: null,
            sex: null,
            birth_date: null,
            notes: null,
            summary: null,
            enabled: null
        },

        errors: {
            name: null,
            nationality: null,
            national_id: null,
            email: null,
            phone: null,
            home_address: null,
            post_address: null,
            growth: null,
            weight: null,
            sex: null,
            birth_date: null,
            notes: null,
            summary: null,
        },

        devices: null,
        updateTime: null,
        checked: null,
        anonymised: null,
        sex_items: [],

        dialogDanger: false,
        dialogDangerItem: null,
        dependenciesData: null,

        progress: true,
        saveProgress: false,
        dangerProgress: false,
        dangerDialogSaveProgress: false,
        save_snackbar: false,
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data(this).errors);

            this.form.birth_date = this.pickedDate.year + "-" + String(this.pickedDate.month).padStart(2, '0') + "-" + String(this.pickedDate.day).padStart(2, '0');

            return validator(
                this.form,
                patientValidation.update,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        async load() {
            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }

            const res = await this.$store.dispatch("api/patient/get", { id: this.form.id });

            if (res.status == 200) {
                let patient = res.data.patient;
                let devices = res.data.devices;
                let date = new Date(patient.birth_date);

                if (!patient.anonymised) {
                    this.pickedDate = {
                        year: date.getFullYear(),
                        month: date.getMonth() + 1,
                        day: date.getDate()
                    };
                } else {
                    this.pickedDate = {
                        year: null,
                        month: null,
                        day: null
                    };
                }

                this.form.name = patient.name;
                this.form.nationality = patient.nationality;
                this.form.national_id = patient.national_id;
                this.form.email = patient.email;
                this.form.phone = patient.phone;
                this.form.home_address = patient.home_address;
                this.form.post_address = patient.post_address;
                this.form.growth = patient.growth;
                this.form.weight = patient.weight;
                this.form.sex = patient.anonymised ? null : patient.sex;
                this.form.notes = patient.notes;
                this.form.summary = patient.summary;
                this.form.enabled = patient.enabled;
                this.form.birth_date = this.pickedDate.year + "-" + String(this.pickedDate.month).padStart(2, '0') + "-" +  String(this.pickedDate.day).padStart(2, '0');
                this.updateTime = patient.updateTime ? new Date(patient.updateTime) : null;
                this.checked = patient.checked;
                this.anonymised = patient.anonymised;
                this.devices = devices;

                this.progress = false;
            } else {
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }

            return;
        },

        async save() {
            if (!this.validate()) return;

            this.saveProgress = true;
            await this.$store.dispatch("api/patient/update", this.form);
            await this.load();
            this.saveProgress = false;
            this.save_snackbar = true;
        },

        async beforeDangerZoneAction() {
            this.dangerProgress = true;
            await this.load();
            this.dependenciesData = null;
            this.dangerProgress = false;
            this.dialogDanger = true;
        },

        async dangerZoneAction(item) {
            switch (item.itemKey) {
                case "delete":
                    this.dangerDialogSaveProgress = true;
                    this.dependenciesData = null;
                    const res = await this.$store.dispatch("api/patient/delete", { id: this.form.id });
                    if (res.status==405) {
                        this.dependenciesData = res.data.data;
                        this.dangerDialogSaveProgress = false;
                        return;
                    } else if (res.status==200) {
                        this.$router.push({ name: 'patients' })
                    } else {
                        this.error = {
                            type: "network",
                            msg: res.data.error.code,
                            code: res.status
                        }
                        this.dialogDanger = false;
                        this.dangerDialogSaveProgress = false;
                    }
                    break;
                case "changeStatus":
                    this.dangerDialogSaveProgress = true;
                    let tempForm = Object.assign({}, this.form);
                    tempForm.enabled = !this.form.enabled;
                    await this.$store.dispatch("api/patient/update", tempForm);
                    await this.load();
                    this.dialogDanger = false;
                    this.dangerDialogSaveProgress = false;
                    break;
                case "anonymise":
                    this.dangerDialogSaveProgress = true;
                    this.dependenciesData = null;
                    const res2 = await this.$store.dispatch("api/patient/anonymise", { id: this.form.id });
                    if (res2.status==405) {
                        this.dependenciesData = res2.data.data;
                        this.dangerDialogSaveProgress = false;
                        return;
                    } else if (res2.status==200) {
                        await this.load();
                        this.dialogDanger = false;
                        this.dangerDialogSaveProgress = false;
                        this.$emit('reload');
                    } else {
                        this.error = {
                            type: "network",
                            msg: res2.data.error.code,
                            code: res2.status
                        }
                        this.dialogDanger = false;
                        this.dangerDialogSaveProgress = false;
                    }
                    break;
            }
        }
    }
};
</script>
