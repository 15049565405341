<template>
    <Fragment :progress="progress" :error="error" @reload="load()">
        <ActionBar :updateTime="updateTime">
            <v-btn v-if="checked" :loading="saveProgress" :disabled="saveProgress || anonymised" color="primary" @click="save()"> 
                <v-icon left>mdi-content-save-outline</v-icon> {{$t('save')}} 
            </v-btn>
            <v-btn v-if="!checked" :loading="saveProgress" :disabled="saveProgress || anonymised" color="red darken-1" class="white--text" @click="save()"> 
                <v-icon left>mdi-check-bold</v-icon> {{$t('confirm')}} 
            </v-btn>
        </ActionBar>

        <Card :title="$t('patient.balance.summary')" class="mt-3">
            <v-textarea
                :label="$t('patient.form.summary')"
                v-model="form.summary"
                rows=4
                auto-grow
                :error-messages="errors.summary"
                :hide-details="!errors.summary"
                :disabled="saveProgress || anonymised"
                @change="validate('summary')"
                outlined
                prepend-inner-icon="mdi-note-outline"
            />

            <div v-if="balance.notCanceledList && balance.notCanceledList.length > 1">
                <v-simple-table class="mt-5" dense>
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left"> {{ $t("patient.balance.list.end") }}</th>
                            <th class="text-left" v-for="(h, index) in balance.headers" :key="index">{{ format(h) }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(param, index) in balance.params" :key="index">
                            <td>{{param.name}}</td>
                            <td v-for="(rate, ind) in param.rates" :key="ind">
                                <v-icon :color="rate.color">{{rate.icon}}</v-icon> <span class="text-caption">{{rate.text}}</span>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </div>
        </Card>

        <Card :title="$t('patient.balance.registry')" class="mt-3">
            <v-simple-table v-if="balance.list && balance.list.length > 0" id="balance-table">
                <thead>
                    <tr>
                        <th class="primary--text">
                            {{ $t("patient.balance.list.test") }}
                        </th>
                        <th class="primary--text">
                            {{ $t("patient.balance.list.end") }}
                        </th>
                        <th class="primary--text">
                            {{ $t("patient.balance.list.time") }}
                        </th>
                        <th class="primary--text text-center">
                            {{ $t("patient.balance.list.status") }}
                        </th>
                        <th class="primary--text w-action">
                            {{ $t("patient.balance.list.edit") }}
                        </th>
                    </tr>
                </thead>

                <tbody style="white-space: nowrap;">
                    <template v-for="(e, index) in balance.list">
                        <tr :key="index">
                            <td :style="'border-bottom: ' + (e.params && !e.canceled ? 0 : 1)">
                                <tr>
                                    <span class="font-weight-bold"><v-icon size="16">mdi-walk</v-icon> {{ $t('patient.balance.list.test') + ' ' + (balance.list.length - index) }}</span>
                                </tr>
                            </td>

                            <td :style="'border-bottom: ' + (e.params && !e.canceled ? 0 : 1)">
                                {{ format(e.end) }}
                            </td>

                            <td :style="'border-bottom: ' + (e.params && !e.canceled ? 0 : 1)">
                                {{ toHHMMSS(e.time) }}
                            </td>

                            <td class="text-center" :style="'border-bottom: ' + (e.params && !e.canceled ? 0 : 1)">
                                <span v-if="e.canceled" class="red--text text--darken-1"> <v-icon class="mr-1 red--text text--darken-1" size="16">mdi-close</v-icon> {{ $t('patient.balance.canceled') }} </span>
                                <span v-else-if="e.params && Object.keys(e.params).length > 0" class="green--text"> <v-icon class="mr-1 green--text" size="16">mdi-check</v-icon> {{ $t('patient.balance.rated') }} </span>
                                <span v-else> <v-icon class="orange--text" size="20">mdi-timer-sand</v-icon> {{ $t("patient.balance.no_rate_param") }} </span>
                            </td>

                            <td class="w-action" :style="'border-bottom: ' + (e.params && !e.canceled ? 0 : 1)">
                                <v-btn
                                    fab
                                    color="primary"
                                    x-small
                                    dark
                                    depressed
                                    @click="balance.selected = e._id; balance.selected_title = $t('patient.balance.list.test') + ' ' + (balance.list.length - index); balance.open = true;"
                                >
                                    <v-icon>mdi-cog</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        <tr :key="index + balance.list.length" class="no-hover">
                            <td colspan="5" class="py-1" style="vertical-align: center; height: 35px; background-color: #EEEEEE" v-if="e.params && !e.canceled">
                                <span class="mr-2 grey--text text--darken-1">{{ $t('patient.balance.params.param_1') + ": " }} <strong> {{ (e.params && e.params.param_1 ? e.params.param_1 : "-") }} </strong></span>
                                <span class="mr-2 grey--text text--darken-1">{{ $t('patient.balance.params.param_2') + ": " }} <strong> {{ (e.params && e.params.param_2 ? e.params.param_2 : "-") }} </strong></span>
                                <span class="mr-2 grey--text text--darken-1">{{ $t('patient.balance.params.param_3') + ": " }} <strong> {{ (e.params && e.params.param_3 ? e.params.param_3 : "-") }} </strong></span>
                                <span class="mr-2 grey--text text--darken-1">{{ $t('patient.balance.params.param_4') + ": " }} <strong> {{ (e.params && e.params.param_4 ? e.params.param_4 : "-") }} </strong></span>
                                <span class="mr-2 grey--text text--darken-1">{{ $t('patient.balance.params.param_5') + ": " }} <strong> {{ (e.params && e.params.param_5 ? e.params.param_5 : "-") }} </strong></span>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </v-simple-table>
            <v-container v-else fluid class="pa-2 d-flex align-center justify-center">
                <div class="text-center">
                    <v-icon size="60" class="mb-2">mdi-timer-sand</v-icon>

                    <div class="text-h6 font-weight-regular">{{ $t('patient.balance.no_data') }}</div>
                </div>
            </v-container>
        </Card>
        
        <BalanceOverview
            v-if="balance.open"
            :open="balance.open"
            :id="balance.selected"
            :title="balance.selected_title"
            @cancel="balance.open = false; load()" 
        />

        <SaveSnackBar v-model="save_snackbar" :text="$t('save_snackbar')" />
    </Fragment>
</template>

<style lang="scss">
    .no-hover:hover {
        background-color: transparent !important;
    }
</style>

<script>
import Fragment from "../components/Fragment.vue";
import Card from "../components/Card.vue";
import ActionBar from "../components/ActionBar.vue";
import BalanceOverview from "../dialogs/BalanceOverview.vue";
import validator from "../../plugins/validator";
import * as patientValidation from "../../api/patient/validation";
import SaveSnackBar from "../components/SaveSnackBar.vue";

export default {
    name: "PatientBalance",
    components: {
        Fragment,
        Card,
        ActionBar,
        BalanceOverview,
        SaveSnackBar
    },

    async mounted() {
        this.form.id = this.$route.params.id;
        this.formBalance.patient = this.$route.params.id;

        this.load();
    },

    data: () => ({
        error: {
            type: null,
            msg: null,
            code: null
        },

        form: {
            id: null,
            name: null,
            nationality: null,
            national_id: null,
            email: null,
            phone: null,
            home_address: null,
            post_address: null,
            growth: null,
            weight: null,
            sex: null,
            birth_date: null,
            notes: null,
            summary: null,
            enabled: null
        },

        errors: {
            summary: null,
        },

        formBalance: {
            type: "balance",
            patient: null,
            page: 1,
            limit: null,
            filter: null,
            sort: "-end ",
        },

        balance:{
            selected: null,
            selected_title: null,
            list: null,
            notCanceledList: null,
            open: false,
            max: 6,
            max_params: 5,
            headers: [],
            params: []
        },

        devices: null,
        updateTime: null,
        checked: null,
        anonymised: null,

        progress: true,
        saveProgress: false,
        save_snackbar: false,
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data(this).errors);

            return validator(
                this.form,
                patientValidation.update,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        async load() {
            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }
            
            const res = await this.$store.dispatch("api/patient/get", { id: this.form.id });
            const resTest = await this.$store.dispatch("api/patient/test_all", this.formBalance);

            if (res.status == 200 && resTest.status == 200) {
                let patient = res.data.patient;
                let devices = res.data.devices;

                this.form.name = patient.name;
                this.form.nationality = patient.nationality;
                this.form.national_id = patient.national_id;
                this.form.email = patient.email;
                this.form.phone = patient.phone;
                this.form.home_address = patient.home_address;
                this.form.post_address = patient.post_address;
                this.form.growth = patient.growth;
                this.form.weight = patient.weight;
                this.form.sex = patient.sex;
                this.form.notes = patient.notes;
                this.form.summary = patient.summary;
                this.form.enabled = patient.enabled;
                this.form.birth_date = patient.birth_date;
                this.updateTime = patient.updateTime ? new Date(patient.updateTime) : null;
                this.checked = patient.checked;
                this.anonymised = patient.anonymised;
                this.devices = devices;

                this.loadBalance(resTest.data)

                this.progress = false;
            } else {
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }

            return;
        },

        async save() {
            if (!this.validate()) return;

            this.saveProgress = true;
            await this.$store.dispatch("api/patient/update", this.form);
            await this.load();
            this.saveProgress = false;
            this.save_snackbar = true;
        },

        loadBalance(data){
            this.balance.list = data;
            this.balance.notCanceledList = data.filter((e) => {return e.canceled != true;});

            this.balance.params = [];
            this.balance.headers = [];

            if(this.balance.notCanceledList.length > 1){
                for(let i = this.balance.notCanceledList.length - 2; i >= 0; i--){
                    this.balance.headers.push(this.balance.notCanceledList[i].end);
                }

                for(let p = 1; p <= this.balance.max_params; p++){
                    let rates = [];
                    //this.list[0].params["param_"+p] = 2;
                    for(let i = this.balance.notCanceledList.length - 2; i >= 0; i--){
                        let test = this.balance.notCanceledList[i];
                        let rate = null;
                        if(test.params) rate = test.params["param_"+p];
                        //let min = 0;
                        //let max = 10;
                        //rate = test.params["param_"+p] = Math.floor(Math.random() * (max - min)) + min;
                        if(rate && rate > 0) {
                            if (this.balance.notCanceledList[i + 1].params && this.balance.notCanceledList[i + 1].params["param_"+p]) {
                                let last_rate = this.balance.notCanceledList[i + 1].params["param_"+p];
                                if(rate == 10) rates.push({
                                    color: "#008000",
                                    icon: "mdi-emoticon-cool-outline",
                                    text: this.$t("patient.balance.rate_perfect")
                                });
                                else if(rate > last_rate) rates.push({
                                    color: "green",
                                    icon: "mdi-emoticon-excited-outline",
                                    text: this.$t("patient.balance.rate_better")
                                });
                                else if(rate < last_rate) rates.push({
                                    color: "red",
                                    icon: "mdi-emoticon-sad-outline",
                                    text: this.$t("patient.balance.rate_worse")
                                });
                                else rates.push({
                                    color: "blue",
                                    icon: "mdi-emoticon-neutral-outline",
                                    text: this.$t("patient.balance.rate_nochange")
                                });
                            } else {
                                rates.push({
                                        color: "grey",
                                        icon: "mdi-help-circle-outline",
                                        text: this.$t("patient.balance.no_previous_rate_param")
                                });
                            }
                        } else {
                            rates.push({
                                color: "orange",
                                icon: "mdi-timer-sand",
                                text: this.$t("patient.balance.no_rate_param")
                            });
                        };
                    }

                    this.balance.params.push({
                        name: this.$t("patient.balance.params.param_"+p),
                        rates: rates
                    });
                }
            }
        },

        toHHMMSS(str) {
            var sec_num = parseInt(str, 10);
            var hours   = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);
            if (hours   < 10) hours   = "0"+hours;
            if (minutes < 10) minutes = "0"+minutes;
            if (seconds < 10) seconds = "0"+seconds;
            return hours+':'+minutes+':'+seconds;
        },

        format(date){
            let dd = new Date(date);
            let d = dd.toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric" });
            let t = String(dd.getHours()).padStart(2, "0")+":"+String(dd.getMinutes()).padStart(2, "0");
            return d+" "+t;
        }
    }
};
</script>
