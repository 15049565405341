<template>
    <Fragment :progress="progress" :error="error" @reload="load()">

        <Card :title="$t('patient.training.summary')" class="mt-3">
            <BarChart
                ref="ch_time"
                :data="chart_time"
                :min="0"
                :max="100"
                height="200px"
            />

            <BarChart
                class="mt-3"
                ref="ch_effective"
                :data="chart_effective"
                :min="0"
                :max="100"
                height="200px"
            />
        </Card>

        <Card :title="$t('patient.training.registry')" class="mt-3">
            <v-simple-table v-if="training.list && training.list.length > 0">
                <thead>
                    <tr>
                        <th class="primary--text">
                            {{ $t("patient.training.list.training_session") }}
                        </th>
                        <th class="primary--text">
                            {{ $t("patient.training.list.end") }}
                        </th>
                        <th class="primary--text">
                            {{ $t("patient.training.list.time") }}
                        </th>
                        <th class="primary--text w-action">
                            {{ $t("patient.training.list.edit") }}
                        </th>
                    </tr>
                </thead>

                <tbody style="white-space: nowrap;">
                    <tr v-for="(e, index) in training.list" :key="index">
                        <td class="pt-3 pb-3">
                            <tr>
                                <span class="font-weight-bold"><v-icon size="16">mdi-run</v-icon> {{ $t("patient.training.list.training_session") + " " + (training.list.length - index) }}</span>
                            </tr>
                        </td>

                        <td>
                            {{ format(e.end) }}
                        </td>

                        <td>
                            {{ toHHMMSS(e.time) }}
                        </td>

                        <td class="w-action">
                            <v-btn
                                fab
                                color="primary"
                                x-small
                                dark
                                depressed
                                @click="training.selected = e._id; training.selected_title = $t('patient.training.list.training_session') + ' ' + (training.list.length - index); training.open = true;"
                            >
                                <v-icon>mdi-cog</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <v-container v-else fluid class="pa-2 d-flex align-center justify-center">
                <div class="text-center">
                    <v-icon size="60" class="mb-2">mdi-timer-sand</v-icon>

                    <div class="text-h6 font-weight-regular">{{ $t('patient.training.no_data') }}</div>
                </div>
            </v-container>
        </Card>
        
        <TrainingOverview
            v-if="training.open"
            :open="training.open"
            :id="training.selected"
            :title="training.selected_title"
            @cancel="training.open = false" 
        />
    </Fragment>
</template>

<script>
import Fragment from "../components/Fragment.vue";
import Card from "../components/Card.vue";
import ActionBar from "../components/ActionBar.vue";
import TrainingOverview from "../dialogs/TrainingOverview.vue";
import BarChart from "../components/BarChart.vue";

export default {
    name: "PatientTraining",
    components: {
        Fragment,
        Card,
        ActionBar,
        TrainingOverview,
        BarChart
    },

    async mounted() {
        this.formTraining.patient = this.$route.params.id;

        this.load();
    },

    data: () => ({
        max_days: 14,

        error: {
            type: null,
            msg: null,
            code: null
        },

        formTraining: {
            type: "workout",
            patient: null,
            page: 1,
            limit: null,
            filter: null,
            sort: "-end ",
        },

        training:{
            selected: null,
            selected_title: null,
            list: null,
            open: false
        },

        chart_time: {
            labels: [],
            datasets: [
                BarChart.methods.newDataset("Czas ćwiczeń (minuty)", "#00695C", "#00695CBB"),
            ]
        },

        chart_effective: {
            labels: [],
            datasets: [
                BarChart.methods.newDataset("Efektywność", "#D98240", "#D98240BB"),
            ]
        },

        progress: true,
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load() {
            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }
            
            const res = await this.$store.dispatch("api/patient/test_all", this.formTraining);

            if (res.status == 200) {
                this.training.list = res.data;

                this.progress = false;

                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.chartsLoad();
                    });
                });
            } else {
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }

            return;
        },

        chartsLoad(){
            const data_time = {
                labels: [],
                datasets: this.chart_time.datasets
            }
            
            const data_effective = {
                labels: [],
                datasets: this.chart_effective.datasets
            }

            data_time.datasets[0].data = [];
            data_effective.datasets[0].data = [];

            for(let d = this.max_days - 1; d >= 0; d--){
                let date = new Date();
                date.setHours(0,0,0,0);
                date.setDate(date.getDate() - d);
                let formatted = date.toLocaleDateString([], {day: "2-digit", month: "2-digit" });

                const data = this.getDay(date);

                data_time.labels.push(formatted);
                data_time.datasets[0].data.push(data.sum_time);
                data_effective.labels.push(formatted);
                data_effective.datasets[0].data.push(data.avg_effective);
            }
            
            this.chart_time = data_time;
            this.chart_effective = data_effective;
        },

        getDay(date){
            const start = date.getTime();
            const end = new Date(start).setHours(23, 59, 59, 0);

            const data = {
                n: 0,
                sum_time: 0,
                sum_effective: 0,
                avg_effective: 0,
            }

            for(let e of this.training.list){
                let e_end = new Date(e.end);

                if(e_end >= start && e_end <= end){
                    data.n++;
                    data.sum_time += e.time;
                    data.sum_effective += e.effective;
                }
            }

            data.sum_time = Math.ceil(data.sum_time / 60);
            if(data.n > 0) data.avg_effective = Math.ceil(data.sum_effective / data.n);
            return data;
        },

        toHHMMSS(str) {
            var sec_num = parseInt(str, 10);
            var hours   = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);
            if (hours   < 10) hours   = "0"+hours;
            if (minutes < 10) minutes = "0"+minutes;
            if (seconds < 10) seconds = "0"+seconds;
            return hours+':'+minutes+':'+seconds;
        },

        format(date){
            let dd = new Date(date);
            let d = dd.toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric" });
            let t = String(dd.getHours()).padStart(2, "0")+":"+String(dd.getMinutes()).padStart(2, "0");
            return d+" "+t;
        }
    }
};
</script>
