<template>
    <Fragment :progress="progress" :error="error" @reload="load()">
        <v-simple-table class="mt-3">
            <thead>
                <tr>
                    <th class="primary--text">
                        {{ $t("call.list.name") }}
                    </th>

                    <th class="primary--text">
                        {{ $t("call.list.type") }}
                    </th>

                    <th class="primary--text">                        
                        {{ $t('call.list.start') }}
                    </th>

                    <th class="primary--text">
                        {{ $t("call.list.status") }}
                    </th>

                    <th class="primary--text w-action">
                        {{ $t("call.list.options") }}
                    </th>
                </tr>
            </thead>
            <tbody style="white-space: nowrap;">
                <tr v-for="(c, index) in calls" :key="index">
                    <td class="pt-3 pb-3">
                        <tr>
                            <span class="font-weight-bold">
                                {{ (c.name) ? c.name : (c.patient) ? $t("call.list.patient")+": "+ c.patient.name : $t("call.no_name") }}
                            </span>
                        </tr>
                        <tr>
                            <span class="grey--text text--darken-2">
                                <v-icon size="16">mdi-clipboard-text-outline</v-icon> {{ (c.info) ? c.info : $t("call.list.no_info")}}
                            </span>
                        </tr>
                    </td>

                    <td>
                        {{$t("call.types."+c.type)}}
                    </td>

                    <td>
                        <tr>
                            <v-icon class="mr-1">mdi-calendar</v-icon>
                            {{ (new Date(c.start)).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric"}) }}
                            {{ (new Date(c.start)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit' }) }} -
                            {{ (new Date(c.end)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit' }) }}
                            {{ endTime(c.start, c.end) }}
                        </tr>
                    </td>

                    <td>
                         <tr>
                            <span :class="status(c.status)" v-if="c.enabled">
                                <v-icon size="16">mdi-list-status</v-icon> {{ $t("call.status." + c.status) }}
                            </span>
                            <span class="text-right red--text text--darken-2" v-else>
                                <v-icon size="16">mdi-list-status</v-icon> {{ $t("call.status.disabled") }}
                            </span>
                        </tr>
                    </td>

                    <td class="w-action">
                        <v-btn
                            fab
                            color="primary"
                            x-small
                            dark
                            depressed
                            :to="{ name: 'call', params: { id: c._id, routefrom: 'patients+patient.'+form.id } }"
                        >
                            <v-icon>mdi-cog</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </Fragment>
</template>

<script>
import Fragment from "../components/Fragment.vue";
import Card from "../components/Card.vue";

export default {
    name: "PatientCalls",
    components: {
        Fragment,
        Card
    },

    async mounted() {
        this.form.id = this.$route.params.id;
        this.load();
    },

    data: () => ({
        error: {
            type: null,
            msg: null,
            code: null
        },

        form: {
            id: null,
            name: null,
            sex: null,
            birth_date: null,
            enabled: null
        },
        
        calls: null,
        progress: true
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load() {

            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }
            
            const res = await this.$store.dispatch("api/patient/get", { id: this.form.id });

            if (res.status == 200) {
                let patient = res.data.patient;
                let calls = res.data.calls;

                this.form.name = patient.name;
                this.form.sex = patient.sex;
                this.form.birth_date = patient.birth_date;
                this.form.enabled = patient.enabled;
                this.calls = calls;

                this.progress = false;
            } else {
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }

            return;
        },

        endTime(start, end){
            let time,
                diff = (new Date(end).getTime() - new Date(start).getTime())/1800000;
            if(diff<2){
                time =  " (" + diff*30 + this.$t('call.min') +")";
            }else{
                time = " (" + 30*diff/60 + this.$t('call.hour') + ")";
            }
            return time;
        },

        status(status){
            if (status == "idle" ) return "text-right blue-grey--text text--darken-1";
            if (status == "during" ) return "text-right light-blue--text text--darken-3";
            if (status == "end" ) return "text-right green--text text--darken-1";
            if (status == "fail" ) return "text-right red--text text--darken-2";
            if (status == "canceled" ) return "text-right red--text text--darken-2";
            return "text-right blue-grey--text text--darken-1";
        }
    }
};
</script>
