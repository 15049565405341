<template>
    <Dialog :fullscreen="true" :progress="progress" :title="title" icon="mdi-walk" :open="open" :cancel="$t('close')" :saveProgress="saveProgress" @cancel="$emit('cancel')" @save="save()">
        <Card>
            <v-row>
                <v-col class="d-flex col-12 col-md-4">
                    <v-btn 
                        v-if="!progress" 
                        :loading="generateCsvProgress" 
                        :disabled="saveProgress || dangerProgress" 
                        color="primary"
                        rounded
                        @click="generateCsv()"
                    > 
                        <v-icon left>mdi-download</v-icon> {{$t('patient.balance.test.csv')}} 
                    </v-btn>

                    <v-btn
                        v-if="!progress"
                        rounded
                        color="secondary"
                        class="ml-2"
                    >
                        {{this.test.entries.length}}
                    </v-btn>

                    <v-btn
                        rounded
                        color="secondary"
                        class="ml-2"
                    >
                        {{this.from}} | {{this.to}}
                    </v-btn>
                </v-col>

                <v-col class="d-flex col-12 col-md-4">
                    <v-slider
                        v-model="slider"
                        :disabled="saveProgress || dangerProgress"
                        thumb-label
                        hide-details
                        :min="1"
                        :max="max_slider"
                        @change="chartsLoad()"
                    />
                </v-col>

                <v-col class="d-flex col-12 col-md-4 justify-center justify-md-end">
                    <v-btn
                        :disabled="position == 0 || saveProgress || dangerProgress"
                        rounded
                        color="primary"
                        @click="position--; chartsLoad();"
                    >
                        <v-icon>mdi-skip-previous-outline</v-icon>
                    </v-btn>

                    <v-btn
                        rounded
                        color="secondary"
                        class="mr-2 ml-2"
                    >
                        {{position}} / {{max_position}}
                    </v-btn>

                    <v-btn
                        :disabled="position == max_position || saveProgress || dangerProgress"
                        rounded
                        color="primary"
                        @click="position++; chartsLoad();"
                    >
                        <v-icon>mdi-skip-next-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </Card>

        <Card :title="$t('patient.balance.test.gyroscope')" id="gyroChart">
            <LineChart
                ref="ch_gyro"
                :data="chart_gyro"
                :min="-250"
                :max="250"
                height="350px"
                @on-receive="selectionMenu"
            />
        </Card>

        <div v-if="test" class="d-flex justify-end caption my-1">
            {{$t('patient.balance.test.gyroscope_calibration')}}: (X, Y, Z) =
            ({{test.calibration_gyro_x ? test.calibration_gyro_x : 0}},
            {{ test.calibration_gyro_y ? test.calibration_gyro_y : 0}},
            {{ test.calibration_gyro_z ? test.calibration_gyro_z : 0}})
        </div>

        <Card :title="$t('patient.balance.test.accelerometer')" id="accelChart">
            <LineChart
                ref="ch_accel"
                :data="chart_accel"
                :min="-2.5"
                :max="2.5"
                height="350px"
                @on-receive="selectionMenu"
            />
        </Card>

        <v-row class="my-0">
            <v-col class="col-12 col-md-4 pb-md-0">
                <Card v-if="test" :title="$t('patient.balance.test.border_points.title')" height="100%">
                    <v-simple-table class="mt-2" dense>
                        <thead>
                            <tr>
                                <th class="text-left"> {{ $t('patient.balance.test.border_points.action') }} </th>
                                <th class="text-left"> {{ $t('patient.balance.test.border_points.time_in_sec') }} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> <v-icon class="mr-2" :color="colors.firstAndLast" x-small>mdi-circle</v-icon> {{ $t('patient.balance.test.border_points.first_move') }} </td>
                                <td> {{ its(form.sectionPoints.firstMove) }} </td>
                            </tr>
                            <tr>
                                <td> <v-icon class="mr-2" :color="colors.rotation" x-small>mdi-circle</v-icon> {{ $t('patient.balance.test.border_points.rotation_start') }} </td>
                                <td> {{ its(form.sectionPoints.rotation) }} </td>
                            </tr>
                            <tr>
                                <td> <v-icon class="mr-2" :color="colors.sittingDown" x-small>mdi-circle</v-icon> {{ $t('patient.balance.test.border_points.sitting_down_start') }} </td>
                                <td> {{ its(form.sectionPoints.sitDownStart) }} </td>
                            </tr>
                            <tr>
                                <td> <v-icon class="mr-2" :color="colors.sittingDown" x-small>mdi-circle</v-icon> {{ $t('patient.balance.test.border_points.sitting_down_end') }} </td>
                                <td> {{ its(form.sectionPoints.sitDownEnd) }} </td>
                            </tr>
                            <tr>
                                <td> <v-icon class="mr-2" :color="colors.stabilisation" x-small>mdi-circle</v-icon> {{ $t('patient.balance.test.border_points.sitting_stabilisation_start') }} </td>
                                <td> {{ its(form.sectionPoints.sittingStart) }} </td>
                            </tr>
                            <tr>
                                <td> <v-icon class="mr-2" :color="colors.gettingUp" x-small>mdi-circle</v-icon> {{ $t('patient.balance.test.border_points.getting_up_start') }} </td>
                                <td> {{ its(form.sectionPoints.getUpStart) }} </td>
                            </tr>
                            <tr>
                                <td> <v-icon class="mr-2" :color="colors.gettingUp" x-small>mdi-circle</v-icon> {{ $t('patient.balance.test.border_points.getting_up_end') }} </td>
                                <td> {{ its(form.sectionPoints.getUpEnd) }} </td>
                            </tr>
                            <tr>
                                <td> <v-icon class="mr-2" :color="colors.firstAndLast" x-small>mdi-circle</v-icon> {{ $t('patient.balance.test.border_points.last_move') }} </td>
                                <td> {{ its(form.sectionPoints.lastMove) }} </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    
                    <template v-slot:actions>
                        <v-btn :loading="setDefaultProgress" :disabled="saveProgress || dangerProgress || defaultCalculationsFlag" color="primary" @click="setDefaultSectionPoints()"> 
                            <v-icon left>mdi-restore</v-icon> {{$t('patient.balance.test.border_points.set_default')}} 
                        </v-btn>
                    </template>
                </Card>
            </v-col>

            <v-col class="col-12 col-md-4 pt-0 pt-md-3 pb-md-0">
                <Card v-if="test" :title="$t('patient.balance.test.parameters.title')" height="100%">
                    <v-simple-table class="mt-2 flex" dense>
                        <thead>
                            <tr>
                                <th class="text-left"> {{ $t('patient.balance.test.parameters.parameter_name') }} </th>
                                <th class="text-left"> {{ $t('patient.balance.test.parameters.value') }} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> {{ $t('patient.balance.test.parameters.upright_standing_stability') }} </td>
                                <td> {{ parameters.uprightStandingStability }} </td>
                            </tr>
                            <tr>
                                <td> {{ $t('patient.balance.test.parameters.reaction_time') }} </td>
                                <td> {{ parameters.reactionTime }} </td>
                            </tr>
                            <tr>
                                <td> {{ $t('patient.balance.test.parameters.walking_time_1') }} </td>
                                <td> {{ parameters.walkingTime1 }} </td>
                            </tr>
                            <tr>
                                <td> {{ $t('patient.balance.test.parameters.body_balance_evenness_1') }} </td>
                                <td> {{ parameters.bodyBalance1 }} </td>
                            </tr>
                            <tr>
                                <td> {{ $t('patient.balance.test.parameters.sitting_down_time') }} </td>
                                <td> {{ parameters.sitDownTime }} </td>
                            </tr>
                            <tr>
                                <td> {{ $t('patient.balance.test.parameters.sitting_down_symmetry') }} </td>
                                <td> {{ parameters.sitDownSymmetry }} </td>
                            </tr>
                            <tr>
                                <td> {{ $t('patient.balance.test.parameters.sitting_stability') }} </td>
                                <td> {{ parameters.sittingStability }} </td>
                            </tr>
                            <tr>
                                <td> {{ $t('patient.balance.test.parameters.getting_up_time') }} </td>
                                <td> {{ parameters.getUpTime }} </td>
                            </tr>
                            <tr>
                                <td> {{ $t('patient.balance.test.parameters.getting_up_symmetry') }} </td>
                                <td> {{ parameters.getUpSymmetry }} </td>
                            </tr>
                            <tr>
                                <td> {{ $t('patient.balance.test.parameters.walking_time_2') }} </td>
                                <td> {{ parameters.walkingTime2 }} </td>
                            </tr>
                            <tr>
                                <td> {{ $t('patient.balance.test.parameters.body_balance_evenness_2') }} </td>
                                <td> {{ parameters.bodyBalance2 }} </td>
                            </tr>
                            <tr>
                                <td> {{ $t('patient.balance.test.parameters.average_body_balance_evenness') }} </td>
                                <td> {{ parameters.averageBodyBalance }} </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </Card>
            </v-col>

            <v-col class="col-12 col-md-4 pt-0 pt-md-3 pb-0">
                <Card v-if="test" :title="$t('patient.balance.test.rating.title')" height="100%">
                    <v-simple-table class="mt-2" dense>
                        <thead>
                            <tr>
                                <th class="text-left"> {{ $t('patient.balance.test.parameters.parameter_name') }} </th>
                                <th class="text-center"> {{ $t('patient.balance.test.rating.suggested_rate') }} </th>
                                <th class="text-center"> {{ $t('patient.balance.test.rating.final_rate') }} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-no-wrap"> {{ $t('patient.balance.params.param_1') }} </td>
                                <td class="text-center"> {{ suggested_params.param_1 }} </td>
                                <td class="d-flex justify-center align-center font-weight-bold">
                                    <div style="width: 80px;">
                                        <v-select
                                            v-model="form.params.param_1"
                                            :items="rate_items"
                                            hide-details
                                            background-color = "transparent" 
                                            :disabled="saveProgress || dangerProgress"
                                            solo
                                            flat
                                            dense
                                        >
                                            <template v-slot:selection="{ item }">
                                                <span class="d-flex justify-end mr-2" style="width: 100%; font-size: 14px;">
                                                    {{ item }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-no-wrap"> {{ $t('patient.balance.params.param_2') }} </td>
                                <td class="text-center"> {{ suggested_params.param_2 }} </td>
                                <td class="d-flex justify-center align-center font-weight-bold">
                                    <div style="width: 80px;">
                                        <v-select
                                            v-model="form.params.param_2"
                                            :items="rate_items"
                                            hide-details
                                            background-color = "transparent" 
                                            :disabled="saveProgress || dangerProgress"
                                            solo
                                            flat
                                            single-line
                                            dense
                                        >
                                            <template v-slot:selection="{ item }">
                                                <span class="d-flex justify-end mr-2" style="width: 100%; font-size: 14px;">
                                                    {{ item }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-no-wrap"> {{ $t('patient.balance.params.param_3') }} </td>
                                <td class="text-center"> {{ suggested_params.param_3 }} </td>
                                <td class="d-flex justify-center align-center font-weight-bold">
                                    <div style="width: 80px;">
                                        <v-select
                                            v-model="form.params.param_3"
                                            :items="rate_items"
                                            hide-details
                                            background-color = "transparent" 
                                            :disabled="saveProgress || dangerProgress"
                                            solo
                                            flat
                                            single-line
                                            dense
                                        >
                                            <template v-slot:selection="{ item }">
                                                <span class="d-flex justify-end mr-2" style="width: 100%; font-size: 14px;">
                                                    {{ item }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-no-wrap"> {{ $t('patient.balance.params.param_4') }} </td>
                                <td class="text-center"> {{ suggested_params.param_4 }} </td>
                                <td class="d-flex justify-center align-center font-weight-bold">
                                    <div style="width: 80px;">
                                        <v-select
                                            v-model="form.params.param_4"
                                            :items="rate_items"
                                            hide-details
                                            background-color = "transparent" 
                                            :disabled="saveProgress || dangerProgress"
                                            solo
                                            flat
                                            single-line
                                            dense
                                        >
                                            <template v-slot:selection="{ item }">
                                                <span class="d-flex justify-end mr-2" style="width: 100%; font-size: 14px;">
                                                    {{ item }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-no-wrap"> {{ $t('patient.balance.params.param_5') }} </td>
                                <td class="text-center"> {{ suggested_params.param_5 }} </td>
                                <td class="d-flex justify-center align-center font-weight-bold">
                                    <div style="width: 80px; margin-top: 2px;">
                                        <v-select
                                            v-model="form.params.param_5"
                                            :items="rate_items"
                                            hide-details
                                            background-color = "transparent" 
                                            :disabled="saveProgress || dangerProgress"
                                            solo
                                            flat
                                            single-line
                                            dense
                                        >
                                            <template v-slot:selection="{ item }">
                                                <span class="d-flex justify-end mr-2" style="width: 100%; font-size: 14px;">
                                                    {{ item }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>

                    <v-divider class="mt-3 mb-3">
                    </v-divider>

                    <v-simple-table dense>
                        <thead>
                            <tr>
                                <th class="text-left"> {{ $t('patient.balance.test.rating.additional_factors') }} </th>
                                <th class="text-center"> {{ $t('patient.balance.test.rating.patient_answer') }} </th>
                                <th class="text-center"> {{ $t('patient.balance.test.rating.final_answer') }} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-no-wrap"> {{ $t('patient.balance.test.rating.supported') }} </td>
                                <td class="text-center"> {{ test.question ? $t("yes") : $t("no") }} </td>
                                <td class="d-flex justify-center align-center font-weight-bold">
                                    <div style="width: 80px;">
                                        <v-select
                                            v-model="form.question_adjusted"
                                            :items="question_items"
                                            hide-details
                                            background-color = "transparent" 
                                            :disabled="saveProgress || dangerProgress"
                                            item-text="name"
                                            item-value="key"
                                            solo
                                            flat
                                            dense
                                            @input="param4Correction()"
                                        >
                                            <template v-slot:selection="{ item }">
                                                <span class="d-flex justify-end mr-2" style="width: 100%; font-size: 14px;">
                                                    {{ item.name }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </Card>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="col-12 pt-0 pb-3">
                <Card v-if="test" :title="$t('patient.balance.test.params_history.title')">
                    <v-simple-table v-if="test && params_history && params_history.length > 0" class="mt-2">
                        <thead>
                            <tr>
                                <th class="text-left"> {{ $t('patient.balance.test.params_history.time') }} </th>
                                <th class="text-left"> {{ $t('patient.balance.test.params_history.user') }} </th>
                                <th class="text-center"> {{ $t('patient.balance.params.param_1') }} </th>
                                <th class="text-center"> {{ $t('patient.balance.params.param_2') }} </th>
                                <th class="text-center"> {{ $t('patient.balance.params.param_3') }} </th>
                                <th class="text-center"> {{ $t('patient.balance.params.param_4') }} </th>
                                <th class="text-center"> {{ $t('patient.balance.params.param_5') }} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(e, index) in params_history" :key="index">
                                <td class="text-left"> <span class="font-weight-medium">{{ new Date(e.createdAt).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric"}) }}</span> <span>{{ new Date(e.createdAt).toLocaleTimeString([], {second: "2-digit", minute: "2-digit", hour: "2-digit"}) }}</span></td>
                                <td class="text-left"> <v-icon size="20">mdi-account</v-icon> {{ (e.user && e.user.name) ? e.user.name : $t('user_removed') }} </td>
                                <td class="text-center"> {{ e.params.param_1 }} </td>
                                <td class="text-center"> {{ e.params.param_2 }} </td>
                                <td class="text-center"> {{ e.params.param_3 }} </td>
                                <td class="text-center"> {{ e.params.param_4 }} </td>
                                <td class="text-center"> {{ e.params.param_5 }} </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-container v-else fluid class="pa-2 d-flex align-center justify-center">
                        <div class="text-center">
                            <v-icon size="60" class="mb-2">mdi-timer-sand</v-icon>

                            <div class="text-h6 font-weight-regular">{{ $t('patient.balance.test.params_history.no_data') }}</div>
                        </div>
                    </v-container>
                </Card>
            </v-col>
        </v-row>

        <DangerZone
            @btnClick="beforeDangerZoneAction()"
            v-model="dialogDangerItem"
            :data="[
                {
                    itemKey: 'changeStatus',
                    itemName: $t('patient.balance.danger_zone.change_status'),
                    itemInfo: $t('patient.balance.danger_zone.change_status_info') + ' ',
                    itemInfoGreen: (!form.canceled ? $t('active') : null),
                    itemInfoRed: (!form.canceled ? null : $t('canceled')),
                    itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'changeStatus' ? dangerProgress : false),
                    itemDisabled: form.canceled
                }
            ]"
            :disabled="saveProgress || dangerProgress"
        />

        <DangerZoneConfirm
            v-if="dialogDanger"
            :title="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return $t('patient.balance.danger_zone.change_status');
                    break;
            }}"
            :description="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return $t('patient.balance.danger_zone.dialog.change_status_desc');
                    break;
            }}"
            :descriptionContent="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return (!form.canceled ? $t('canceled') : $t('active'));
                    break;
            }}"
            :checkText="() => title"
            :open="dialogDanger"
            :save="dialogDangerItem.itemName"
            :saveProgress="dangerDialogSaveProgress"
            @cancel="dialogDanger = false" 
            @save="dangerZoneAction(dialogDangerItem)"
        />

        <v-menu 
            v-model="showSelectionMenu"
            :position-x="this.menuX + 10"
            :position-y="this.menuY + 10"
            :close-on-click="closeOnClickSelectionMenu"
            absolute
            offset-y
        >
            <v-list
                dense
            >
                <v-row>
                    <v-col class="d-flex col-10">
                        <v-subheader class="ml-1 text-uppercase">{{ $t('patient.balance.test.border_points.set_border_point') }}</v-subheader>
                    </v-col>
                    <v-col class="d-flex col-2 justify-end">
                        <v-subheader class="mr-1 text-uppercase font-weight-bold"><div v-if="test && clickedPoint">{{ its(clickedPoint) }}</div></v-subheader>
                    </v-col>
                </v-row>
                <v-list-item-group
                    v-model="selectedMenuItem"
                    color="primary"
                >
                    <v-list-item @click="setSectionPoint(1)">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="mr-2" :color="colors.firstAndLast" x-small>mdi-circle</v-icon>
                                {{ $t('patient.balance.test.border_points.first_move') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="setSectionPoint(2)">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="mr-2" :color="colors.rotation" x-small>mdi-circle</v-icon>
                                {{ $t('patient.balance.test.border_points.rotation_start') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="setSectionPoint(3)">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="mr-2" :color="colors.sittingDown" x-small>mdi-circle</v-icon>
                                {{ $t('patient.balance.test.border_points.sitting_down_start') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="setSectionPoint(4)">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="mr-2" :color="colors.sittingDown" x-small>mdi-circle</v-icon>
                                {{ $t('patient.balance.test.border_points.sitting_down_end') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="setSectionPoint(5)">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="mr-2" :color="colors.stabilisation" x-small>mdi-circle</v-icon>
                                {{ $t('patient.balance.test.border_points.sitting_stabilisation_start') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="setSectionPoint(6)">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="mr-2" :color="colors.gettingUp" x-small>mdi-circle</v-icon>
                                {{ $t('patient.balance.test.border_points.getting_up_start') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="setSectionPoint(7)">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="mr-2" :color="colors.gettingUp" x-small>mdi-circle</v-icon>
                                {{ $t('patient.balance.test.border_points.getting_up_end') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="setSectionPoint(8)">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="mr-2" :color="colors.firstAndLast" x-small>mdi-circle</v-icon>
                                {{ $t('patient.balance.test.border_points.last_move') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>

        <SaveSnackBar v-model="save_snackbar" :text="$t('save_snackbar')" />
    </Dialog>
</template>

<script>
import Dialog from "../components/Dialog.vue";
import Card from "../components/Card.vue";
import LineChart from "../components/LineChart.vue";
import DangerZone from "../components/DangerZone.vue";
import DangerZoneConfirm from "./DangerZoneConfirm.vue";
import SaveSnackBar from "../components/SaveSnackBar.vue";

export default {
    components: {
        Dialog,
        Card,
        LineChart,
        DangerZone,
        DangerZoneConfirm,
        SaveSnackBar
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        id:{
            type: String,
            default: null
        },

        title:{
            type: String,
            default: null
        }
    },

    mounted(){
        this.form.id = this.id;
        this.load();
        this.question_items.push({
            key: true,
            name: this.$t("yes")
        });

        this.question_items.push({
            key: false,
            name: this.$t("no")
        });
    },

    data: () => ({
        dialogDanger: false,
        save_snackbar: false,
        dialogDangerItem: null,

        showSelectionMenu: false,
        closeOnClickSelectionMenu: true,
        menuX: null,
        menuY: null,
        selectedMenuItem: null,

        clickedPoint: null,

        progress: true,
        saveProgress: false,
        dangerProgress: false,
        dangerDialogSaveProgress: false,
        setDefaultProgress: false,
        generateCsvProgress: false,

        defaultCalculationsFlag: true,
        rate_items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        question_items: [],

        test: null,
        params_history: null,

        slider: 100,
        slider_step: 5,
        max_slider: 100,
        position: 0,
        max_position: 0,
        from: 0,
        to: 0,
        points: [],

        form: {
            id: null,
            canceled: null,
            question_adjusted: null,
            
            sectionPoints: {
                firstMove: null,
                rotation: null,
                sitDownStart: null,
                sitDownEnd: null,
                sittingStart: null,
                getUpStart: null,
                getUpEnd: null,
                lastMove: null,
            },

            params: {
                param_1: null,
                param_2: null,
                param_3: null,
                param_4: null,
                param_5: null
            }
        },

        chart_gyro: {
            labels: [],
            datasets: [
                LineChart.methods.newDataset("X", "#209AF1"),
                LineChart.methods.newDataset("Y", "#71A292"),
                LineChart.methods.newDataset("Z", "#DA6142"),
            ],
            markers: [
                "ok"
            ]
        },

        chart_accel: {
            labels: [],
            datasets: [
                LineChart.methods.newDataset("X", "#209AF1"),
                LineChart.methods.newDataset("Y", "#71A292"),
                LineChart.methods.newDataset("Z", "#DA6142")
            ]
        },

        parameters: {
            uprightStandingStability: null,
            reactionTime: null,
            walkingTime1: null,
            bodyBalance1: null,
            sitDownTime: null,
            sitDownSymmetry: null,
            sittingStability: null,
            getUpTime: null,
            getUpSymmetry: null,
            walkingTime2: null,
            bodyBalance2: null,
            averageBodyBalance: null
        },

        suggested_params: {
            param_1: null,
            param_2: null,
            param_3: null,
            param_4: null,
            param_5: null
        },

        original_param_4: null,

        colors: {
            firstAndLast: "#D50000",
            rotation: "#1565C0",
            sittingDown: "#689F38",
            stabilisation: "#D500F9",
            gettingUp: "#FF9800",
        }
    }),

    methods: {
        async load(){
            const res = await this.$store.dispatch("api/patient/test_get", { id: this.id });

            if(res.status == 200){
                this.test = res.data;
                if (this.test.params_history) this.params_history = this.test.params_history.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
                
                this.form.canceled = res.data.canceled;
                this.form.question_adjusted = (res.data.question_adjusted === true || res.data.question_adjusted === false) ? res.data.question_adjusted : res.data.question;

                this.calculateSectionPoints();

                if (res.data.sectionPoints && Object.keys(res.data.sectionPoints).length > 0) {

                    let names = ["firstMove", "rotation", "sitDownStart", "sitDownEnd", "sittingStart", "getUpStart", "getUpEnd", "lastMove"];
                    for (let name of names) {
                        if (this.form.sectionPoints[name] != res.data.sectionPoints[name]) {
                            this.form.sectionPoints[name] = (res.data.sectionPoints[name] >= 0 && res.data.sectionPoints[name] < this.test.entries.length ? res.data.sectionPoints[name] : 0);
                            this.defaultCalculationsFlag = false;
                        }
                    }
                }

                this.calculateParameters();
                this.calculateRates();
                if (res.data.params && Object.keys(res.data.params).length > 0) {
                    this.form.params = Object.assign({}, res.data.params);
                } else {
                    this.form.params = Object.assign({}, this.suggested_params);
                }

                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.chartsLoad();
                    });
                });

                this.progress = false;
            }
        },

        calculateSectionPoints(){
            if (this.test.entries[0].time + 30000 >= this.test.entries[this.test.entries.length - 1].time) {
                this.form.sectionPoints.firstMove = 0;
                this.form.sectionPoints.rotation = 0;
                this.form.sectionPoints.sitDownStart = 0;
                this.form.sectionPoints.sitDownEnd = 0;
                this.form.sectionPoints.sittingStart = 0;
                this.form.sectionPoints.getUpStart = 0;
                this.form.sectionPoints.getUpEnd = 0;
                this.form.sectionPoints.lastMove = 0;
            } else {
                this.firstMovePoint();
                this.rotationPoint();
                this.downUpPoint();
                this.sittingStartPoint()
                this.lastMovePoint();
            }
        },

        calculateParameters(){
            this.uprightStandingStabilityCalc();
            this.reactionTimeCalc();
            this.walkingTime1Calc();
            this.bodyBalance1Calc();
            this.sitDownTimeCalc();
            this.sitDownSymmetryCalc();
            this.sittingStabilityCalc();
            this.getUpTimeCalc();
            this.getUpSymmetryCalc();
            this.walkingTime2Calc();
            this.bodyBalance2Calc();
            this.averageBodyBalanceCalc();
        },

        calculateRates(){
            let stability = 1;
            let reactionTime = 1;
            let balance = 1;
            let strength = 1;
            let mobility = 1;

            //stability

            if (this.parameters.uprightStandingStability * 0.85 + this.parameters.sittingStability * 0.15 < 0.35) { stability = 1 } else
            if (this.parameters.uprightStandingStability * 0.85 + this.parameters.sittingStability * 0.15 < 0.5) { stability = 2 } else
            if (this.parameters.uprightStandingStability * 0.85 + this.parameters.sittingStability * 0.15 < 0.6) { stability = 3 } else
            if (this.parameters.uprightStandingStability * 0.85 + this.parameters.sittingStability * 0.15 < 0.7) { stability = 4 } else
            if (this.parameters.uprightStandingStability * 0.85 + this.parameters.sittingStability * 0.15 < 0.78) { stability = 5 } else
            if (this.parameters.uprightStandingStability * 0.85 + this.parameters.sittingStability * 0.15 < 0.85) { stability = 6 } else
            if (this.parameters.uprightStandingStability * 0.85 + this.parameters.sittingStability * 0.15 < 0.92) { stability = 7 } else
            if (this.parameters.uprightStandingStability * 0.85 + this.parameters.sittingStability * 0.15 < 0.95) { stability = 8 } else
            if (this.parameters.uprightStandingStability * 0.85 + this.parameters.sittingStability * 0.15 < 0.97) { stability = 9 } else
            { stability = 10 }

            //reactionTime

            if (this.parameters.reactionTime > 3.5) { reactionTime = 1 } else
            if (this.parameters.reactionTime > 2.8) { reactionTime = 2 } else
            if (this.parameters.reactionTime > 2.3) { reactionTime = 3 } else
            if (this.parameters.reactionTime > 1.9) { reactionTime = 4 } else
            if (this.parameters.reactionTime > 1.5) { reactionTime = 5 } else
            if (this.parameters.reactionTime > 1.3) { reactionTime = 6 } else
            if (this.parameters.reactionTime > 1) { reactionTime = 7 } else
            if (this.parameters.reactionTime > 0.8) { reactionTime = 8 } else
            if (this.parameters.reactionTime > 0.5) { reactionTime = 9 } else
            { reactionTime = 10 }

            //balance

            let symmetry = 1;

            if (this.parameters.sitDownSymmetry * 0.5 + this.parameters.getUpSymmetry * 0.5 < 0.35) { symmetry = 1 } else
            if (this.parameters.sitDownSymmetry * 0.5 + this.parameters.getUpSymmetry * 0.5 < 0.5) { symmetry = 2 } else
            if (this.parameters.sitDownSymmetry * 0.5 + this.parameters.getUpSymmetry * 0.5 < 0.6) { symmetry = 3 } else
            if (this.parameters.sitDownSymmetry * 0.5 + this.parameters.getUpSymmetry * 0.5 < 0.7) { symmetry = 4 } else
            if (this.parameters.sitDownSymmetry * 0.5 + this.parameters.getUpSymmetry * 0.5 < 0.78) { symmetry = 5 } else
            if (this.parameters.sitDownSymmetry * 0.5 + this.parameters.getUpSymmetry * 0.5 < 0.85) { symmetry = 6 } else
            if (this.parameters.sitDownSymmetry * 0.5 + this.parameters.getUpSymmetry * 0.5 < 0.92) { symmetry = 7 } else
            if (this.parameters.sitDownSymmetry * 0.5 + this.parameters.getUpSymmetry * 0.5 < 0.95) { symmetry = 8 } else
            if (this.parameters.sitDownSymmetry * 0.5 + this.parameters.getUpSymmetry * 0.5 < 0.97) { symmetry = 9 } else
            { symmetry = 10 }

            
            let bodyBalance = 1;

            if (this.parameters.averageBodyBalance > 25) { bodyBalance = 1 } else
            if (this.parameters.averageBodyBalance > 18) { bodyBalance = 2 } else
            if (this.parameters.averageBodyBalance > 14) { bodyBalance = 3 } else
            if (this.parameters.averageBodyBalance > 11) { bodyBalance = 4 } else
            if (this.parameters.averageBodyBalance > 9) { bodyBalance = 5 } else
            if (this.parameters.averageBodyBalance > 7) { bodyBalance = 6 } else
            if (this.parameters.averageBodyBalance > 5) { bodyBalance = 7 } else
            if (this.parameters.averageBodyBalance > 3) { bodyBalance = 8 } else
            if (this.parameters.averageBodyBalance > 1) { bodyBalance = 9 } else
            { bodyBalance = 10 }

            balance = Math.round(0.8 * bodyBalance + 0.2 * symmetry);

            //strength

            if (this.parameters.getUpTime > 1.9) { strength = 1 } else
            if (this.parameters.getUpTime > 1.75) { strength = 2 } else
            if (this.parameters.getUpTime > 1.6) { strength = 3 } else
            if (this.parameters.getUpTime > 1.5) { strength = 4 } else
            if (this.parameters.getUpTime > 1.4) { strength = 5 } else
            if (this.parameters.getUpTime > 1.35) { strength = 6 } else
            if (this.parameters.getUpTime > 1.25) { strength = 7 } else
            if (this.parameters.getUpTime > 1.15) { strength = 8 } else
            if (this.parameters.getUpTime > 1) { strength = 9 } else
            { strength = 10 }

            //mobility

            let symmetry2 = 1;

            if (this.parameters.sitDownSymmetry * 0.4 + this.parameters.getUpSymmetry * 0.6 < 0.35) { symmetry2 = 1 } else
            if (this.parameters.sitDownSymmetry * 0.4 + this.parameters.getUpSymmetry * 0.6 < 0.5) { symmetry2 = 2 } else
            if (this.parameters.sitDownSymmetry * 0.4 + this.parameters.getUpSymmetry * 0.6 < 0.6) { symmetry2 = 3 } else
            if (this.parameters.sitDownSymmetry * 0.4 + this.parameters.getUpSymmetry * 0.6 < 0.7) { symmetry2 = 4 } else
            if (this.parameters.sitDownSymmetry * 0.4 + this.parameters.getUpSymmetry * 0.6 < 0.78) { symmetry2 = 5 } else
            if (this.parameters.sitDownSymmetry * 0.4 + this.parameters.getUpSymmetry * 0.6 < 0.85) { symmetry2 = 6 } else
            if (this.parameters.sitDownSymmetry * 0.4 + this.parameters.getUpSymmetry * 0.6 < 0.92) { symmetry2 = 7 } else
            if (this.parameters.sitDownSymmetry * 0.4 + this.parameters.getUpSymmetry * 0.6 < 0.95) { symmetry2 = 8 } else
            if (this.parameters.sitDownSymmetry * 0.4 + this.parameters.getUpSymmetry * 0.6 < 0.97) { symmetry2 = 9 } else
            { symmetry2 = 10 }

            let sitDownTime = 1;

            if (this.parameters.sitDownTime > 3) { sitDownTime = 1 } else
            if (this.parameters.sitDownTime > 2.6) { sitDownTime = 2 } else
            if (this.parameters.sitDownTime > 2.4) { sitDownTime = 3 } else
            if (this.parameters.sitDownTime > 2.2) { sitDownTime = 4 } else
            if (this.parameters.sitDownTime > 2) { sitDownTime = 5 } else
            if (this.parameters.sitDownTime > 1.8) { sitDownTime = 6 } else
            if (this.parameters.sitDownTime > 1.6) { sitDownTime = 7 } else
            if (this.parameters.sitDownTime > 1.4) { sitDownTime = 8 } else
            if (this.parameters.sitDownTime > 1.2) { sitDownTime = 9 } else
            { sitDownTime = 10 }

            let walkingTime = 1

            if (this.parameters.walkingTime1 * 0.5 + this.parameters.walkingTime2 * 0.5 > 8) { walkingTime = 1 } else
            if (this.parameters.walkingTime1 * 0.5 + this.parameters.walkingTime2 * 0.5 > 7.5) { walkingTime = 2 } else
            if (this.parameters.walkingTime1 * 0.5 + this.parameters.walkingTime2 * 0.5 > 6.5) { walkingTime = 3 } else
            if (this.parameters.walkingTime1 * 0.5 + this.parameters.walkingTime2 * 0.5 > 6) { walkingTime = 4 } else
            if (this.parameters.walkingTime1 * 0.5 + this.parameters.walkingTime2 * 0.5 > 5.5) { walkingTime = 5 } else
            if (this.parameters.walkingTime1 * 0.5 + this.parameters.walkingTime2 * 0.5 > 5) { walkingTime = 6 } else
            if (this.parameters.walkingTime1 * 0.5 + this.parameters.walkingTime2 * 0.5 > 4.5) { walkingTime = 7 } else
            if (this.parameters.walkingTime1 * 0.5 + this.parameters.walkingTime2 * 0.5 > 4) { walkingTime = 8 } else
            if (this.parameters.walkingTime1 * 0.5 + this.parameters.walkingTime2 * 0.5 > 3.5) { walkingTime = 9 } else
            { walkingTime = 10 }

            mobility = Math.round(walkingTime * 0.6 + sitDownTime * 0.15 + symmetry2 * 0.25);

            //results

            this.suggested_params = {
                param_1: stability,
                param_2: reactionTime,
                param_3: balance,
                param_4: null,
                param_5: mobility
            }

            this.original_param_4 = strength;
            this.param4Correction();

        },

        param4Correction() {
            let temp = Object.assign({}, this.suggested_params);
            temp.param_4 = Math.max(this.original_param_4 - (this.form.question_adjusted ? 3 : 0), 1);
            this.suggested_params = temp;
        },

        setDefaultSectionPoints() {
            this.setDefaultProgress = true;

            this.calculateSectionPoints();
            this.calculateParameters();
            this.calculateRates();

            this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.chartsLoad();
                    });
                });

            this.defaultCalculationsFlag = true;
            this.setDefaultProgress = false;
        },

        selectionMenu (data) {
            this.selectedMenuItem = null;

            if (!this.showSelectionMenu) {
                this.menuX = data.e.clientX;
                this.menuY = data.e.clientY;
    	        this.clickedPoint = data.index + this.slider_step * this.slider * this.position + (this.position == this.max_position && this.position != 0 ? this.test.entries.length % (this.slider * this.slider_step) - this.slider * this.slider_step : 0);
            }
            this.$nextTick(() => {
                this.showSelectionMenu = true;
            });
        },

        setSectionPoint (section_point_number) {
            switch (section_point_number) {
                case 1:
                    this.form.sectionPoints.firstMove = this.clickedPoint;
                    break;
                case 2:
                    this.form.sectionPoints.rotation = this.clickedPoint;
                    break;
                case 3:
                    this.form.sectionPoints.sitDownStart = this.clickedPoint;
                    break;
                case 4:
                    this.form.sectionPoints.sitDownEnd = this.clickedPoint;
                    break;
                case 5:
                    this.form.sectionPoints.sittingStart = this.clickedPoint;
                    break;
                case 6:
                    this.form.sectionPoints.getUpStart = this.clickedPoint;
                    break;
                case 7:
                    this.form.sectionPoints.getUpEnd = this.clickedPoint;
                    break;
                case 8:
                    this.form.sectionPoints.lastMove = this.clickedPoint;
                    break;
                default:
                    return;
            }

            this.calculateParameters();
            this.calculateRates();

            this.defaultCalculationsFlag = false;

            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.chartsLoad();
                });
            });
        },

        chartsLoad(){
            const chart_gyro = this.$refs.ch_gyro;
            const chart_accel = this.$refs.ch_accel;

            const gyro = {
                labels: [],
                datasets: this.chart_gyro.datasets
            }
            
            const accel = {
                labels: [],
                datasets: this.chart_accel.datasets
            }

            gyro.datasets[0].data = [];
            gyro.datasets[1].data = [];
            gyro.datasets[2].data = [];
            
            accel.datasets[0].data = [];
            accel.datasets[1].data = [];
            accel.datasets[2].data = [];

            if(this.position < 0) this.position = 0;
            if(this.slider == 0) this.slider = 1;

            if(this.slider_step * this.max_slider > this.test.entries.length) this.max_slider = Math.ceil(this.test.entries.length / this.slider_step);

            let limit = this.slider_step * this.slider;

            this.max_position = Math.floor(this.test.entries.length / limit);
            if(limit == this.test.entries.length) this.max_position--;

            if(limit > this.test.entries.length) limit = this.test.entries.length;

            this.from = limit * this.position;
            this.to = limit * (this.position + 1);

            if(this.position >= this.max_position) {
                this.to = this.test.entries.length;
                this.from = this.to - limit;
                this.position = this.max_position;
            }

            for(let i = this.from; i < this.to; i++){
                let entry = this.test.entries[i];
                const second = this.its(i);
                
                gyro.labels.push(second);
                gyro.datasets[0].data.push(entry.gyro.x);
                gyro.datasets[1].data.push(entry.gyro.y);
                gyro.datasets[2].data.push(entry.gyro.z);

                accel.labels.push(second);
                accel.datasets[0].data.push(entry.accel.x);
                accel.datasets[1].data.push(entry.accel.y);
                accel.datasets[2].data.push(entry.accel.z);
            }

            this.chart_gyro = gyro;
            this.chart_accel = accel;

            chart_gyro.clearMarkers();
            chart_accel.clearMarkers();

            chart_gyro.addMarker(this.its(this.form.sectionPoints.firstMove), "verticalLine", 1, this.colors.firstAndLast);
            chart_accel.addMarker(this.its(this.form.sectionPoints.firstMove), "verticalLine", 1, this.colors.firstAndLast);
            
            chart_gyro.addMarker(this.its(this.form.sectionPoints.rotation), "verticalLine", 1, this.colors.rotation);
            chart_accel.addMarker(this.its(this.form.sectionPoints.rotation), "verticalLine", 1, this.colors.rotation);
            
            chart_gyro.addMarker(this.its(this.form.sectionPoints.sitDownStart), "verticalLine", 1, this.colors.sittingDown);
            chart_accel.addMarker(this.its(this.form.sectionPoints.sitDownStart), "verticalLine", 1, this.colors.sittingDown);
            
            chart_gyro.addMarker(this.its(this.form.sectionPoints.sitDownEnd), "verticalLine", 1, this.colors.sittingDown);
            chart_accel.addMarker(this.its(this.form.sectionPoints.sitDownEnd), "verticalLine", 1, this.colors.sittingDown);
            
            chart_gyro.addMarker(this.its(this.form.sectionPoints.sittingStart), "verticalLine", 1, this.colors.stabilisation);
            chart_accel.addMarker(this.its(this.form.sectionPoints.sittingStart), "verticalLine", 1, this.colors.stabilisation);
            
            chart_gyro.addMarker(this.its(this.form.sectionPoints.getUpStart), "verticalLine", 1, this.colors.gettingUp);
            chart_accel.addMarker(this.its(this.form.sectionPoints.getUpStart), "verticalLine", 1, this.colors.gettingUp);

            chart_gyro.addMarker(this.its(this.form.sectionPoints.getUpEnd), "verticalLine", 1, this.colors.gettingUp);
            chart_accel.addMarker(this.its(this.form.sectionPoints.getUpEnd), "verticalLine", 1, this.colors.gettingUp);

            chart_gyro.addMarker(this.its(this.form.sectionPoints.lastMove), "verticalLine", 1, this.colors.firstAndLast);
            chart_accel.addMarker(this.its(this.form.sectionPoints.lastMove), "verticalLine", 1, this.colors.firstAndLast);
        },

        async save() {
            //if (!this.validate()) return; to będzie potrzebne, jeśli mają być jakieś pola wpisywane ręcznie, ale chyba nie

            this.saveProgress = true;
            await this.$store.dispatch("api/patient/test_update", this.form);
            await this.load();
            this.saveProgress = false;
            this.save_snackbar = true;
        },

        async beforeDangerZoneAction() {
            this.dangerProgress = true;
            await this.load();
            this.dangerProgress = false;
            this.dialogDanger = true;
        },

        async dangerZoneAction(item) {
            switch (item.itemKey) {
                case "changeStatus":
                    this.dangerDialogSaveProgress = true;
                    let tempForm = Object.assign({}, this.form);
                    tempForm.canceled = !this.form.canceled;
                    await this.$store.dispatch("api/patient/test_update", tempForm);
                    await this.load();
                    this.dialogDanger = false;
                    this.dangerDialogSaveProgress = false;
                    break;
            }
        },

        firstMovePoint(){
            const end_time = this.test.entries[0].time + 30000;
            let average_accel_x = 1;
            let sum_accel_x = 0;
            let counter = 0;
            let diff_tab = [];
            let i5 = 0;

            for (let i = 0; i < this.test.entries.length; i++) {
                if (this.test.entries[i].time - this.test.entries[0].time > 5000) {
                    break;
                } else {
                    i5 = i;
                }
            }

            for(let i = i5; i < this.test.entries.length; i++){
                sum_accel_x += this.test.entries[i].accel.x;
                counter++;

                if (this.test.entries[i].time > end_time || i == this.test.entries.length - 1) {
                    average_accel_x = sum_accel_x/counter;
                    break;
                }
            }

            let cut = parseInt(0.95 * counter);

            for(let i = 0; i < counter; i++){
                let diff = Math.abs(this.test.entries[i + i5].accel.x - average_accel_x);
                diff_tab.push(diff);
            }

            diff_tab.sort((a, b) => a - b);

            let entry_index = counter + i5;

            for(let i = counter + i5; i < this.test.entries.length; i++){

                if (Math.abs(this.test.entries[i].accel.x - average_accel_x) > diff_tab[cut]) {
                    entry_index = i;
                    break;
                }
            }

            this.form.sectionPoints.firstMove = entry_index;
        },

        rotationPoint(){
            let max_peak_gyro_x_val = Math.abs(this.test.entries[this.form.sectionPoints.firstMove].gyro.x);
            let first_peak_gyro_x_index = this.form.sectionPoints.firstMove;

            for(let i = this.form.sectionPoints.firstMove; i < this.test.entries.length; i++){
                max_peak_gyro_x_val = Math.max(max_peak_gyro_x_val, Math.abs(this.test.entries[i].gyro.x))
            }

            let min_max = 0;

            for(let i = this.form.sectionPoints.firstMove + 1; i < this.test.entries.length - 1; i++){
                if (Math.abs(this.test.entries[i].gyro.x) > 0.8 * max_peak_gyro_x_val) {
                    if (this.test.entries[i].gyro.x > this.test.entries[i-1].gyro.x && this.test.entries[i].gyro.x > this.test.entries[i+1].gyro.x) {
                        min_max = 1;
                        first_peak_gyro_x_index = i;
                        break;
                    }
                    else if (this.test.entries[i].gyro.x < this.test.entries[i-1].gyro.x && this.test.entries[i].gyro.x < this.test.entries[i+1].gyro.x) {
                        min_max = -1;
                        first_peak_gyro_x_index = i;
                        break;
                    }
                }
            }

            let entry_index = this.form.sectionPoints.firstMove;

            for(let i = first_peak_gyro_x_index - 1; i > this.form.sectionPoints.firstMove; i--){
                if (min_max = 1 && this.test.entries[i].gyro.x < this.test.entries[i-1].gyro.x && this.test.entries[i].gyro.x < this.test.entries[i+1].gyro.x) {
                    entry_index = i;
                    break;
                }
                if (min_max = -1 && this.test.entries[i].gyro.x > this.test.entries[i-1].gyro.x && this.test.entries[i].gyro.x > this.test.entries[i+1].gyro.x) {
                    entry_index = i;
                    break;
                }
            }

            this.form.sectionPoints.rotation = entry_index;
        },

        downUpPoint(){
            let ext_tab = [];
            let ext_map_diff = new Map();
            let max_change_index = this.form.sectionPoints.firstMove;
            let max_change2_index = this.form.sectionPoints.firstMove;
            let max_change_index_end = this.form.sectionPoints.firstMove;
            let max_change2_index_end = this.form.sectionPoints.firstMove;

            for(let i = this.form.sectionPoints.firstMove + 1; i < this.test.entries.length - 1; i++){
                if (this.test.entries[i].gyro.y > this.test.entries[i-1].gyro.y && this.test.entries[i].gyro.y > this.test.entries[i+1].gyro.y) {
                    ext_tab.push(i);
                }
                else if (this.test.entries[i].gyro.y < this.test.entries[i-1].gyro.y && this.test.entries[i].gyro.y < this.test.entries[i+1].gyro.y) {
                    ext_tab.push(i);
                }
            }

            for (let i = 0; i < ext_tab.length - 1; i++){
                ext_map_diff.set(ext_tab[i], this.test.entries[ext_tab[i + 1]].gyro.y - this.test.entries[ext_tab[i]].gyro.y);
            }

            let last_index = ext_tab.pop();
            ext_tab.sort((a, b) => ext_map_diff.get(a) - ext_map_diff.get(b));

            if(Math.abs(ext_map_diff.get(ext_tab[0])) > Math.abs(ext_map_diff.get(ext_tab[ext_tab.length - 1]))) {
                max_change_index = ext_tab[0];
                max_change2_index = ext_tab[1];
            } else {
                max_change_index = ext_tab[ext_tab.length - 1];
                max_change2_index = ext_tab[ext_tab.length - 2];
            }

            if (!max_change_index) max_change_index = this.form.sectionPoints.firstMove;
            if (!max_change2_index) max_change2_index = this.form.sectionPoints.firstMove;

            max_change_index = (this.adjustExt(max_change_index, -1, 0.8, "gyro", "y") < this.form.sectionPoints.firstMove ? max_change_index : this.adjustExt(max_change_index, -1, 0.8, "gyro", "y"));
            max_change2_index = (this.adjustExt(max_change2_index, -1, 0.8, "gyro", "y") < this.form.sectionPoints.firstMove ? max_change2_index : this.adjustExt(max_change2_index, -1, 0.8, "gyro", "y"));

            ext_tab.push(last_index);
            ext_tab.sort((a, b) => a - b);

            for (let i = 1; i < ext_tab.length - 1; i++) {
                if (max_change_index == ext_tab[i]) {
                    max_change_index = Math.max(ext_tab[i-1], this.form.sectionPoints.firstMove);
                    max_change_index_end = ext_tab[i+1];
                }
                if (max_change2_index == ext_tab[i]) {
                    max_change2_index = Math.max(ext_tab[i-1], this.form.sectionPoints.firstMove);
                    max_change2_index_end = ext_tab[i+1];
                }
            }

            if (!max_change_index_end) max_change_index_end = this.form.sectionPoints.firstMove;
            if (!max_change2_index_end) max_change2_index_end = this.form.sectionPoints.firstMove;


            max_change_index_end = this.adjustExt(max_change_index_end, 1, 0.8, "gyro", "y");
            max_change2_index_end = this.adjustExt(max_change2_index_end, 1, 0.8, "gyro", "y");

            max_change_index = (this.adjustFlat(max_change_index, 1, 5, "gyro", "y") < max_change_index_end ? this.adjustFlat(max_change_index, 1, 5, "gyro", "y") : max_change_index);
            max_change2_index = (this.adjustFlat(max_change2_index, 1, 5, "gyro", "y") < max_change2_index_end ? this.adjustFlat(max_change2_index, 1, 5, "gyro", "y") : max_change2_index);

            let sitDownStart = Math.min(max_change_index, max_change2_index);
            let sitDownEnd = Math.min(max_change_index_end, max_change2_index_end);
            let getUpStart = Math.max(max_change_index, max_change2_index);
            let getUpEnd = Math.max(max_change_index_end, max_change2_index_end);

            this.form.sectionPoints.sitDownStart = sitDownStart;
            this.form.sectionPoints.sitDownEnd = sitDownEnd;
            this.form.sectionPoints.getUpStart = getUpStart;
            this.form.sectionPoints.getUpEnd = getUpEnd;
        },

        sittingStartPoint(){
            let entry_index = this.findStabilisation(this.form.sectionPoints.sitDownEnd, this.form.sectionPoints.sitDownEnd + 8);
            this.form.sectionPoints.sittingStart = entry_index;
        },

        lastMovePoint(){
            let entry_index = this.findStabilisation(this.form.sectionPoints.getUpEnd, this.test.entries.length, 6);

            this.form.sectionPoints.lastMove = entry_index;
        },
        
        findStabilisation(startIndex, endIndex, stabilisationLength) {
            let sum_abs_diff = 0;

            let diff_tab = [];
            let diff_tab2 = [];

            for(let i = startIndex + 1; i < Math.min(endIndex + 1, this.test.entries.length - (stabilisationLength ? stabilisationLength : 1)); i++){
                for (let j = i ; j < (stabilisationLength ? i + stabilisationLength : Math.min(endIndex, this.test.entries.length - 1)); j++){
                    sum_abs_diff += Math.pow(this.test.entries[j+1].gyro.x - this.test.entries[j].gyro.x, 2);
                    sum_abs_diff += Math.pow(this.test.entries[j+1].gyro.y - this.test.entries[j].gyro.y, 2);
                    sum_abs_diff += Math.pow(this.test.entries[j+1].gyro.z - this.test.entries[j].gyro.z, 2);
                    sum_abs_diff += Math.pow(100*this.test.entries[j+1].accel.x - 100*this.test.entries[j].accel.x, 2);
                    sum_abs_diff += Math.pow(100*this.test.entries[j+1].accel.y - 100*this.test.entries[j].accel.y, 2);
                    sum_abs_diff += Math.pow(100*this.test.entries[j+1].accel.z - 100*this.test.entries[j].accel.z, 2);
                }

                sum_abs_diff = Math.sqrt(sum_abs_diff);
                if (sum_abs_diff > 1) diff_tab.push([i, sum_abs_diff]);
                sum_abs_diff = 0;
            }

            for (let i = 1; i < diff_tab.length; i++){
                let percent_change = diff_tab[i][1]/diff_tab[i-1][1];
                diff_tab2.push([diff_tab[i][0], percent_change]);
            }
            
            return (diff_tab2.length == 0 ? Math.min(endIndex + 1, this.test.entries.length - 1) : diff_tab2.sort((a, b) => a[1] - b[1])[0][0]);
        },

        adjustExt(index, direction, confidenceLevel, ga, xyz){
            let result = index;
            if (ga == "gyro") {
                if (xyz == "x") {
                    if (this.test.entries[index].gyro.x > this.test.entries[index - 1].gyro.x && this.test.entries[index].gyro.x > this.test.entries[index + 1].gyro.x) {
                        for (let i = index + direction; i < this.test.entries.length - 1 && i > 0; i = i + direction) {
                            if (Math.abs(this.test.entries[i].gyro.x) < confidenceLevel * Math.abs(this.test.entries[index].gyro.x)) return result;
                            if (this.test.entries[i].gyro.x > this.test.entries[i - 1].gyro.x && this.test.entries[i].gyro.x > this.test.entries[i + 1].gyro.x) result = i;
                        }
                    } else if (this.test.entries[index].gyro.x < this.test.entries[index - 1].gyro.x && this.test.entries[index].gyro.x < this.test.entries[index + 1].gyro.x) {
                        for (let i = index + direction; i < this.test.entries.length - 1 && i > 0; i = i + direction) {
                            if (Math.abs(this.test.entries[i].gyro.x) < confidenceLevel * Math.abs(this.test.entries[index].gyro.x)) return result;
                            if (this.test.entries[i].gyro.x < this.test.entries[i - 1].gyro.x && this.test.entries[i].gyro.x < this.test.entries[i + 1].gyro.x) result = i;
                        }
                    }
                } else if (xyz == "y") {
                    if (this.test.entries[index].gyro.y > this.test.entries[index - 1].gyro.y && this.test.entries[index].gyro.y > this.test.entries[index + 1].gyro.y) {
                        for (let i = index + direction; i < this.test.entries.length - 1 && i > 0; i = i + direction) {
                            if (Math.abs(this.test.entries[i].gyro.y) < confidenceLevel * Math.abs(this.test.entries[index].gyro.y)) return result;
                            if (this.test.entries[i].gyro.y > this.test.entries[i - 1].gyro.y && this.test.entries[i].gyro.y > this.test.entries[i + 1].gyro.y) result = i;
                        }
                    } else if (this.test.entries[index].gyro.y < this.test.entries[index - 1].gyro.y && this.test.entries[index].gyro.y < this.test.entries[index + 1].gyro.y) {
                        for (let i = index + direction; i < this.test.entries.length - 1 && i > 0; i = i + direction) {
                            if (Math.abs(this.test.entries[i].gyro.y) < confidenceLevel * Math.abs(this.test.entries[index].gyro.y)) return result;
                            if (this.test.entries[i].gyro.y < this.test.entries[i - 1].gyro.y && this.test.entries[i].gyro.y < this.test.entries[i + 1].gyro.y) result = i;
                        }
                    }
                } else if (xyz == "z") {
                    if (this.test.entries[index].gyro.z > this.test.entries[index - 1].gyro.z && this.test.entries[index].gyro.z > this.test.entries[index + 1].gyro.z) {
                        for (let i = index + direction; i < this.test.entries.length - 1 && i > 0; i = i + direction) {
                            if (Math.abs(this.test.entries[i].gyro.z) < confidenceLevel * Math.abs(this.test.entries[index].gyro.z)) return result;
                            if (this.test.entries[i].gyro.z > this.test.entries[i - 1].gyro.z && this.test.entries[i].gyro.z > this.test.entries[i + 1].gyro.z) result = i;
                        }
                    } else if (this.test.entries[index].gyro.z < this.test.entries[index - 1].gyro.z && this.test.entries[index].gyro.z < this.test.entries[index + 1].gyro.z) {
                        for (let i = index + direction; i < this.test.entries.length - 1 && i > 0; i = i + direction) {
                            if (Math.abs(this.test.entries[i].gyro.z) < confidenceLevel * Math.abs(this.test.entries[index].gyro.z)) return result;
                            if (this.test.entries[i].gyro.z < this.test.entries[i - 1].gyro.z && this.test.entries[i].gyro.z < this.test.entries[i + 1].gyro.z) result = i;
                        }
                    }
                }
            } else if (ga == "accel") {
                if (xyz == "x") {
                    if (this.test.entries[index].accel.x > this.test.entries[index - 1].accel.x && this.test.entries[index].accel.x > this.test.entries[index + 1].accel.x) {
                        for (let i = index + direction; i < this.test.entries.length - 1 && i > 0; i = i + direction) {
                            if (Math.abs(this.test.entries[i].accel.x) < confidenceLevel * Math.abs(this.test.entries[index].accel.x)) return result;
                            if (this.test.entries[i].accel.x > this.test.entries[i - 1].accel.x && this.test.entries[i].accel.x > this.test.entries[i + 1].accel.x) result = i;
                        }
                    } else if (this.test.entries[index].accel.x < this.test.entries[index - 1].accel.x && this.test.entries[index].accel.x < this.test.entries[index + 1].accel.x) {
                        for (let i = index + direction; i < this.test.entries.length - 1 && i > 0; i = i + direction) {
                            if (Math.abs(this.test.entries[i].accel.x) < confidenceLevel * Math.abs(this.test.entries[index].accel.x)) return result;
                            if (this.test.entries[i].accel.x < this.test.entries[i - 1].accel.x && this.test.entries[i].accel.x < this.test.entries[i + 1].accel.x) result = i;
                        }
                    }
                } else if (xyz == "y") {
                    if (this.test.entries[index].accel.y > this.test.entries[index - 1].accel.y && this.test.entries[index].accel.y > this.test.entries[index + 1].accel.y) {
                        for (let i = index + direction; i < this.test.entries.length - 1 && i > 0; i = i + direction) {
                            if (Math.abs(this.test.entries[i].accel.y) < confidenceLevel * Math.abs(this.test.entries[index].accel.y)) return result;
                            if (this.test.entries[i].accel.y > this.test.entries[i - 1].accel.y && this.test.entries[i].accel.y > this.test.entries[i + 1].accel.y) result = i;
                        }
                    } else if (this.test.entries[index].accel.y < this.test.entries[index - 1].accel.y && this.test.entries[index].accel.y < this.test.entries[index + 1].accel.y) {
                        for (let i = index + direction; i < this.test.entries.length - 1 && i > 0; i = i + direction) {
                            if (Math.abs(this.test.entries[i].accel.y) < confidenceLevel * Math.abs(this.test.entries[index].accel.y)) return result;
                            if (this.test.entries[i].accel.y < this.test.entries[i - 1].accel.y && this.test.entries[i].accel.y < this.test.entries[i + 1].accel.y) result = i;
                        }
                    }
                } else if (xyz == "z") {
                    if (this.test.entries[index].accel.z > this.test.entries[index - 1].accel.z && this.test.entries[index].accel.z > this.test.entries[index + 1].accel.z) {
                        for (let i = index + direction; i < this.test.entries.length - 1 && i > 0; i = i + direction) {
                            if (Math.abs(this.test.entries[i].accel.z) < confidenceLevel * Math.abs(this.test.entries[index].accel.z)) return result;
                            if (this.test.entries[i].accel.z > this.test.entries[i - 1].accel.z && this.test.entries[i].accel.z > this.test.entries[i + 1].accel.z) result = i;
                        }
                    } else if (this.test.entries[index].accel.z < this.test.entries[index - 1].accel.z && this.test.entries[index].accel.z < this.test.entries[index + 1].accel.z) {
                        for (let i = index + direction; i < this.test.entries.length - 1 && i > 0; i = i + direction) {
                            if (Math.abs(this.test.entries[i].accel.z) < confidenceLevel * Math.abs(this.test.entries[index].accel.z)) return result;
                            if (this.test.entries[i].accel.z < this.test.entries[i - 1].accel.z && this.test.entries[i].accel.z < this.test.entries[i + 1].accel.z) result = i;
                        }
                    }
                }
            }
            return result;
        },

        adjustFlat(index, direction, acceptableDiff, ga, xyz, compareValue){
            let result = index;
            if (ga == "gyro") {
                if (xyz == "x") {
                    for (let i = index + direction; i < this.test.entries.length && i >= 0; i = i + direction) {
                        if (Math.abs(this.test.entries[i].gyro.x - (compareValue === undefined? this.test.entries[index].gyro.x : compareValue)) < acceptableDiff) {
                            result = i;
                        } else {
                            return result;;
                        }
                    }
                } else if (xyz == "y") {
                    for (let i = index + direction; i < this.test.entries.length && i >= 0; i = i + direction) {
                        if (Math.abs(this.test.entries[i].gyro.y - (compareValue === undefined? this.test.entries[index].gyro.y : compareValue)) < acceptableDiff) {
                            result = i;
                        } else {
                            return result;;
                        }
                    }
                } else if (xyz == "z") {
                    for (let i = index + direction; i < this.test.entries.length && i >= 0; i = i + direction) {
                        if (Math.abs(this.test.entries[i].gyro.z - (compareValue === undefined? this.test.entries[index].gyro.z : compareValue)) < acceptableDiff) {
                            result = i;
                        } else {
                            return result;;
                        }
                    }
                }
            } else if (ga == "accel") {
                if (xyz == "x") {
                    for (let i = index + direction; i < this.test.entries.length && i >= 0; i = i + direction) {
                        if (Math.abs(this.test.entries[i].accel.x - (compareValue === undefined? this.test.entries[index].accel.x : compareValue)) < acceptableDiff/100) {
                            result = i;
                        } else {
                            return result;;
                        }
                    }
                } else if (xyz == "y") {
                    for (let i = index + direction; i < this.test.entries.length && i >= 0; i = i + direction) {
                        if (Math.abs(this.test.entries[i].accel.y - (compareValue === undefined? this.test.entries[index].accel.y : compareValue)) < acceptableDiff/100) {
                            result = i;
                        } else {
                            return result;;
                        }
                    }
                } else if (xyz == "z") {
                    for (let i = index + direction; i < this.test.entries.length && i >= 0; i = i + direction) {
                        if (Math.abs(this.test.entries[i].accel.z - (compareValue === undefined? this.test.entries[index].accel.z : compareValue)) < acceptableDiff/100) {
                            result = i;
                        } else {
                            return result;;
                        }
                    }
                }
            }
        },

        its(index, precision) {
            return Math.floor(((this.test.entries[index].time - this.test.entries[0].time) / 1000) * Math.pow(10, (precision ? precision : 1))) / Math.pow(10, (precision ? precision : 1));
        },

        uprightStandingStabilityCalc() {
            let sum_gx = 0;
            let sum_gy = 0;
            let sum_gz = 0;
            let dt = 0;
            let i5 = 0;
            

            for (let i = 0; i < this.test.entries.length; i++) {
                if (this.test.entries[i].time - this.test.entries[0].time > 5000) {
                    break;
                } else {
                    i5 = i;
                }
            }
            

            for (let i = i5; this.test.entries[i].time < Math.min(30000 + this.test.entries[0].time, this.test.entries[this.test.entries.length - 1].time); i++) {
                dt = (this.test.entries[i+1].time - this.test.entries[i].time)/1000
                
                sum_gx += Math.abs((this.test.entries[i+1].gyro.x + this.test.entries[i].gyro.x)/2 
                    - (this.test.calibration_gyro_x ? this.test.calibration_gyro_x : 0)) * dt;
                sum_gy += Math.abs((this.test.entries[i+1].gyro.y + this.test.entries[i].gyro.y)/2  
                    - (this.test.calibration_gyro_y ? this.test.calibration_gyro_y : 0)) * dt;
                sum_gz += Math.abs((this.test.entries[i+1].gyro.z + this.test.entries[i].gyro.z)/2  
                    - (this.test.calibration_gyro_z ? this.test.calibration_gyro_z : 0)) * dt;
            }

            let result = Math.floor((1 - (sum_gx + sum_gy + sum_gz)/(1000 * 25/30)) * 100) / 100;

            this.parameters.uprightStandingStability = result;
        },

        reactionTimeCalc() {
            let result = Math.floor((this.test.entries[this.form.sectionPoints.firstMove].time - (this.test.entries[0].time + 30000))/1000 * 100)/100;

            this.parameters.reactionTime = result;
        },

        walkingTime1Calc(){
            let result = Math.floor((this.test.entries[this.form.sectionPoints.sitDownStart].time - this.test.entries[this.form.sectionPoints.firstMove].time)/1000 * 100)/100;

            this.parameters.walkingTime1 = result;
        },

        bodyBalance1Calc(){
            let sum_gx = 0;
            let dt = 0;

            for (let i = this.form.sectionPoints.firstMove; i < this.form.sectionPoints.rotation; i++) {
                dt = (this.test.entries[i+1].time - this.test.entries[i].time)/1000
                
                sum_gx += ((this.test.entries[i+1].gyro.x + this.test.entries[i].gyro.x)/2 
                    - (this.test.calibration_gyro_x ? this.test.calibration_gyro_x : 0)) * dt;
            }

            let result = Math.floor((sum_gx/(this.its(this.form.sectionPoints.rotation, 2) - this.its(this.form.sectionPoints.firstMove, 2))) * 100) / 100;

            this.parameters.bodyBalance1 = result;
        },

        sitDownTimeCalc(){
            let result = Math.floor((this.test.entries[this.form.sectionPoints.sitDownEnd].time - this.test.entries[this.form.sectionPoints.sitDownStart].time)/1000 * 100)/100;

            this.parameters.sitDownTime = result;
        },

        sitDownSymmetryCalc() {
            let sum_gz = 0;
            let dt = 0;

            for (let i = this.form.sectionPoints.sitDownStart; i < this.form.sectionPoints.sitDownEnd; i++) {
                dt = (this.test.entries[i+1].time - this.test.entries[i].time)/1000
                
                sum_gz += Math.abs((this.test.entries[i+1].gyro.z + this.test.entries[i].gyro.z)/2 
                    - (this.test.calibration_gyro_z ? this.test.calibration_gyro_z : 0)) * dt;
            }

            let result = Math.floor((1 - sum_gz/(2000/3 * (this.its(this.form.sectionPoints.sitDownEnd, 2) - this.its(this.form.sectionPoints.sitDownStart, 2))/30)) * 100) / 100;

            this.parameters.sitDownSymmetry = result;
        },

        sittingStabilityCalc() {
            let sum_gx = 0;
            let sum_gy = 0;
            let sum_gz = 0;
            let dt = 0;

            for (let i = this.form.sectionPoints.sittingStart; i < this.form.sectionPoints.getUpStart; i++) {
                dt = (this.test.entries[i+1].time - this.test.entries[i].time)/1000
                
                sum_gx += Math.abs((this.test.entries[i+1].gyro.x + this.test.entries[i].gyro.x)/2 
                    - (this.test.calibration_gyro_x ? this.test.calibration_gyro_x : 0)) * dt;
                sum_gy += Math.abs((this.test.entries[i+1].gyro.y + this.test.entries[i].gyro.y)/2 
                    - (this.test.calibration_gyro_y ? this.test.calibration_gyro_y : 0)) * dt;
                sum_gz += Math.abs((this.test.entries[i+1].gyro.z + this.test.entries[i].gyro.z)/2 
                    - (this.test.calibration_gyro_z ? this.test.calibration_gyro_z : 0)) * dt;
            }

            let result = Math.floor((1 - (sum_gx + sum_gy + sum_gz)/(1000 * (this.its(this.form.sectionPoints.getUpStart, 2) - this.its(this.form.sectionPoints.sittingStart, 2))/30)) * 100) / 100;

            this.parameters.sittingStability = result;
        },

        getUpTimeCalc(){
            let result = Math.floor((this.test.entries[this.form.sectionPoints.getUpEnd].time - this.test.entries[this.form.sectionPoints.getUpStart].time)/1000 * 100)/100;

            this.parameters.getUpTime = result;
        },

        getUpSymmetryCalc() {
            let sum_gz = 0;
            let dt = 0;

            for (let i = this.form.sectionPoints.getUpStart; i < this.form.sectionPoints.getUpEnd; i++) {
                dt = (this.test.entries[i+1].time - this.test.entries[i].time)/1000
                
                sum_gz += Math.abs((this.test.entries[i+1].gyro.z + this.test.entries[i].gyro.z)/2 
                    - (this.test.calibration_gyro_z ? this.test.calibration_gyro_z : 0)) * dt;
            }

            let result = Math.floor((1 - sum_gz/(2000/3 * (this.its(this.form.sectionPoints.getUpEnd, 2) - this.its(this.form.sectionPoints.getUpStart, 2))/30)) * 100) / 100;

            this.parameters.getUpSymmetry = result;
        },

        walkingTime2Calc(){
            let result = Math.floor((this.test.entries[this.form.sectionPoints.lastMove].time - this.test.entries[this.form.sectionPoints.getUpEnd].time)/1000 * 100)/100;

            this.parameters.walkingTime2 = result;
        },
        
        bodyBalance2Calc(){
            let sum_gx = 0;
            let dt = 0;

            for (let i = this.form.sectionPoints.getUpEnd; i < this.form.sectionPoints.lastMove; i++) {
                dt = (this.test.entries[i+1].time - this.test.entries[i].time)/1000
                
                sum_gx += ((this.test.entries[i+1].gyro.x + this.test.entries[i].gyro.x)/2 
                    - (this.test.calibration_gyro_x ? this.test.calibration_gyro_x : 0)) * dt;
            }

            let result = Math.floor((sum_gx/(this.its(this.form.sectionPoints.lastMove, 2) - this.its(this.form.sectionPoints.getUpEnd, 2))) * 100) / 100;

            this.parameters.bodyBalance2 = result;
        },
        
        averageBodyBalanceCalc(){
            let sum_gx_1 = 0;
            let sum_gx_2 = 0;
            let dt = 0;

            for (let i = this.form.sectionPoints.firstMove; i < this.form.sectionPoints.rotation; i++) {
                dt = (this.test.entries[i+1].time - this.test.entries[i].time)/1000
                
                sum_gx_1 += ((this.test.entries[i+1].gyro.x + this.test.entries[i].gyro.x)/2 
                    - (this.test.calibration_gyro_x ? this.test.calibration_gyro_x : 0)) * dt;
            }

            for (let i = this.form.sectionPoints.getUpEnd; i < this.form.sectionPoints.lastMove; i++) {
                dt = (this.test.entries[i+1].time - this.test.entries[i].time)/1000
                
                sum_gx_2 += ((this.test.entries[i+1].gyro.x + this.test.entries[i].gyro.x)/2 
                    - (this.test.calibration_gyro_x ? this.test.calibration_gyro_x : 0)) * dt;
            }

            let result = Math.floor(((Math.abs(sum_gx_1) + Math.abs(sum_gx_2))/(this.its(this.form.sectionPoints.rotation, 2) - this.its(this.form.sectionPoints.firstMove, 2) + this.its(this.form.sectionPoints.lastMove, 2) - this.its(this.form.sectionPoints.getUpEnd, 2))) * 100) / 100;

            this.parameters.averageBodyBalance = result;
        },

        async generateCsv(){
            this.generateCsvProgress = true;
            const res = await this.$store.dispatch("api/patient/generate_test_csv", { id: this.id });

            if(res.status == 200){
                const temp = document.createElement("a");
                temp.href = this.$store.state.config.api.tests + this.id + ".csv";
                temp.download = this.id;
                temp.click();

                this.generateCsvProgress = false;
            }
        }
    }
};
</script>
