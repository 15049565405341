<template>
    <Fragment :progress="progress" :error="error" @reload="load()">
        <v-simple-table class="mt-3">
            <thead>
                <tr>
                    <th class="primary--text">
                        {{ $t('patient.devices.list.name') }}
                    </th>
                    <th class="primary--text">
                        {{ $t('patient.devices.list.sn') }}
                    </th>
                    <th class="primary--text text-center">
                        {{ $t('patient.devices.list.status') }}
                    </th>
                    <th class="primary--text w-action">
                        {{ $t("patient.devices.list.edit") }}
                    </th>
                </tr>
            </thead>
            <tbody style="white-space: nowrap;">
                <tr v-for="(u, index) in devices" :key="index">
                    <td class="pt-3 pb-3">
                        <tr>
                            <span class="font-weight-bold">{{ u.name }}</span>
                        </tr>
                        <tr>
                            <span class="grey--text text--darken-2 d-block text-truncate" style="max-width: 400px;">
                                <v-icon size="16">mdi-clipboard-text-outline</v-icon> {{ (u.info) ? u.info : $t("device.list.no_info") }}
                            </span>
                        </tr>
                    </td>
                    
                    <td>
                        <v-icon class="mr-1" size="16">mdi-counter</v-icon> {{ u.sn }}
                    </td>

                    <td class="text-center">
                        <span v-if="u.enabled" class="green--text"> <v-icon class="mr-1 green--text" size="16">mdi-check</v-icon> {{ $t("active") }} </span>
                        <span v-else class="red--text text--darken-1"> <v-icon class="mr-1 red--text text--darken-1" size="16">mdi-close</v-icon> {{ $t("inactive") }} </span>
                    </td>

                    <td class="w-action">
                        <v-btn
                            fab
                            color="primary"
                            x-small
                            dark
                            depressed
                            :to="{ name: 'device', params: { id: u._id, routefrom: 'patients+patient.'+form.id } }"
                            ><v-icon>mdi-cog</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </Fragment>
</template>

<script>
import Fragment from "../components/Fragment.vue";
import Card from "../components/Card.vue";

export default {
    name: "PatientDevices",
    components: {
        Fragment,
        Card
    },

    async mounted() {
        this.form.id = this.$route.params.id;
        this.load();
    },

    data: () => ({
        error: {
            type: null,
            msg: null,
            code: null
        },

        form: {
            id: null,
            name: null,
            sex: null,
            birth_date: null,
            enabled: null
        },
        
        devices: null,
        progress: true
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load() {

            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }
            
            const res = await this.$store.dispatch("api/patient/get", { id: this.form.id });

            if (res.status == 200) {
                let patient = res.data.patient;
                let devices = res.data.devices;

                this.form.name = patient.name;
                this.form.sex = patient.sex;
                this.form.birth_date = patient.birth_date;
                this.form.enabled = patient.enabled;
                this.devices = devices;

                this.progress = false;
            } else {
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }

            return;
        }
    }
};
</script>
