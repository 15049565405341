<template>
    <bar-chart 
        :styles="{height: height, position: 'relative'}" 
        :chart-data="data" 
        :options="options" 
    />
</template>

<script>
import BarChart from '../../plugins/barchart';
export default {
    components: {BarChart},
    
    props: {
        height:{
            type: String,
            default: '300px'
        },
        data:{
            type: Object
        },
        min:{
            type: Number,
            default: undefined
        },
        max:{
            type: Number,
            default: undefined
        }
    },
    mounted(){
        
    },
    data: (vm) => ({
        markers: [],
        options: {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: 0
            },
            scales: {
                yAxes: [{
                    ticks: {
                        maxTicksLimit: 6,
                        beginAtZero: true,
                        suggestedMin: vm.min,
                        suggestedMax: vm.max,
                    }    
                }]
            },
            elements: {
                point: {
                    radius: vm.pointRadius,
                    pointStyle: vm.pointStyles,
                    hoverRadius: 6,
                }
            },
            tooltips: {
                mode: 'index',
                axis: 'x',
                intersect: false
            },
            verticalLines: []
        },
    }),
    methods:{
        newDataset(label, color, bgcolor){
            return {
                type: 'bar',
                label: label,
                fill: (bgcolor) ? true : false,
                backgroundColor: bgcolor,
                borderColor: color,
                barPercentage: 0.2,
                data: [],
                order: 2
            };
        },

        pointStyles(ctx){
            let index = ctx.dataIndex;
            let label = ctx.chart.config.data.labels[index];
            let marker = this.markers.find((e) => e.label == label);
            return (marker) ? marker.type : 'circle';
        },

        pointRadius(ctx){
            let index = ctx.dataIndex;
            let label = ctx.chart.config.data.labels[index];
            let marker = this.markers.find((e) => e.label == label);
            return (marker) ? marker.radius : 1;
        },

        addMarker(label, type, radius){
            this.markers.push({
                label,
                type,
                radius
            });
            this.options.verticalLines.push({
                label,
                my: 32,
                width: 1,
                color: "#D65E4099"
            });
        }
    }
}
</script>